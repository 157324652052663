import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const changePassword = async (password, newPassword) => {
    const response = await axios.post(routes.changePassword, {
        password, newPassword
    }, AXIOS_DEFAULT_CONFIG);
    return response;
}
export default changePassword;