import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as api from "../../api";
import { ReactComponent as CloseIcon } from "../../assets/images/cross.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/input-error.svg";
import RegistrationHeader from "../Utils/RegistrationHeader";
const Login = ({
  close = () => {},
  handleLogin = () => {},
  handleRegistrationPopup = () => {},
  registrationHeader = {},
  newUser = {},
  setNewUser = () => {},
}) => {
  const [formError, setFormError] = useState("");
  const schema = yup.object({
    email: yup.string().email("Invalid Email").required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    setFormError("");
    api.auth
      .login(data.email, data.password)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          handleLogin(
            response.data.firstName,
            response.data.lastName,
            response.data.gender,
            response.data.email,
            response.data.mobileNumber,
            response.data.pk
          );
          setNewUser({});
          close();
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          setFormError(error.response.data.nonFieldErrors[0]);
        } else {
          setFormError("Something went wrong");
        }
      });
  };

  return (
    <React.Fragment>
      <div className="popup">
        <CloseIcon className="popup__close" onClick={() => close()} />
        <div className="popup__body popup__body--registration">
          {Object.keys(registrationHeader).length !== 0 && (
            <React.Fragment>
              <RegistrationHeader registrationHeader={registrationHeader} />
              <p className="registration-form__lock-paragraph">
                Your passes are locked successfuly
              </p>
              <p className="registration-form__lock-warning">
                Login to be able to checkout
              </p>
            </React.Fragment>
          )}
          {Object.keys(newUser).length !== 0 && (
            <p className="registration-form__new-user-message">
              {newUser.message}
              <span className="registration-form__new-user-email">
                {newUser.email}
              </span>
              {newUser.messageLine2}
            </p>
          )}
          <form
            className={
              "registration-form registration-form--login" +
              (Object.keys(registrationHeader).length !== 0
                ? " registration-form--timed"
                : "")
            }
            onSubmit={handleSubmit(onSubmit)}
          >
            <h3 className="registration-form__title">Login</h3>
            {formError && (
              <p className="error-message registration-form__form-error">
                {formError}
              </p>
            )}
            <div className="registration-form__input-group">
              <label
                htmlFor="email"
                className="registration-form__label registration-form__label--required"
              >
                Email
              </label>
              <div className="registration-form__input-box">
                <input
                  type="text"
                  id="email"
                  {...register("email")}
                  className={
                    "registration-form__input" +
                    (errors.email ? " registration-form__input--error" : "")
                  }
                />
                {errors.email && (
                  <ErrorIcon className="registration-form__input-icon" />
                )}
              </div>
              {errors?.email && (
                <p className="error-message registration-form__input-error-message">
                  {errors?.email?.message}
                </p>
              )}
            </div>
            <div className="registration-form__input-group">
              <label
                htmlFor="password"
                className="registration-form__label registration-form__label--required"
              >
                Password
              </label>
              <div className="registration-form__input-box">
                <input
                  type="password"
                  id="password"
                  {...register("password")}
                  className={
                    "registration-form__input" +
                    (errors.password ? " registration-form__input--error" : "")
                  }
                />
                {errors.password && (
                  <ErrorIcon className="registration-form__input-icon" />
                )}
              </div>
              {errors?.password && (
                <p className="error-message registration-form__input-error-message">
                  {errors?.password?.message}
                </p>
              )}
            </div>
            <p
              className="registration-form__redirect registration-form__forgot-password"
              onClick={() => {
                close();
                handleRegistrationPopup("forgotPassword", true);
              }}
            >
              Forgot your password?
            </p>
            <button
              type="submit"
              className="btn btn--primary registration-form__btn"
            >
              Login
            </button>
            <p className="registration-form__redirect registration-form__redirect--paragraph">
              Don't have an account?
              <span
                className="registration-form__redirect--btn"
                onClick={() => {
                  close();
                  handleRegistrationPopup("register", true);
                }}
              >
                {" "}
                Register
              </span>
            </p>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
