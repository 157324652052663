import React, { useState, useEffect } from "react";
import { ReactComponent as TooltipDarkFilledIcon } from "../../assets/images/tooltip-icon-dark-filled.svg";

const Tooltip = ({ title = "", message = "", tooltipShown = false }) => {
  const [show, setShow] = useState(tooltipShown);
  useEffect(() => {
    setShow(tooltipShown);
  }, [tooltipShown]);

  return (
    <div className="tooltip">
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        onClick={() => setShow(!show)}
      >
        <TooltipDarkFilledIcon className="tooltip__icon tooltip__icon--dark-filled" />
      </div>
      {show && (
        <div className="tooltip__text-box">
          <p className="tooltip__title">{title}</p>
          <p className="tooltip__message">{message}</p>
          {/*  <span className="tooltip__close-icon" onClick={() => setShow(false)}>
            X
          </span> */}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
