import React, { useState, useEffect } from "react";
import { ReactComponent as InfoIcon } from "../../assets/images/tooltip-icon-dark-filled.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/cross.svg";
import { ReactComponent as MapIcon } from "../../assets/images/pin-filled.svg";
import { ReactComponent as MicroPhoneIcon } from "../../assets/images/microphone.svg";
import { Link } from "react-router-dom";
import Overlay from "../Utils/Overlay";
import EventPopup from "./EventPopup";
import { NonceProvider } from "react-select";

const EventSummary = ({ event = {} }) => {
  const [showLayout, setShowLayout] = useState(false);
  const [showEventInfo, setShowEventInfo] = useState(false);
  const [imageButton, setImageButton] = useState({});

  useEffect(() => {
    if (Object.keys(event).length !== 0) {
      if (event.type === "full") {
        setImageButton({ text: "View line up", icon: "MicroPhoneIcon" });
      } else {
        setImageButton({ text: "View site map", icon: "MapIcon" });
      }
    } else {
      setImageButton({ text: "View site map", icon: "MapIcon" });
    }
  }, [event]);

  return (
    <React.Fragment>
      {Object.keys(event).length > 0 && (
        <React.Fragment>
          <div className="event-summary">
            <div className="event-summary__image-container">
              <img src={event.image} alt="" className="event-summary__image" />
            </div>
            <div className="event-summary__body">
              {/* <h3 className="event-summary__artist">
            {event.artists ? event.artists[0]?.name : ""}
          </h3> */}
          {/* 
              <h2 className="event-summary__event-name">{event.title}</h2>
              <div className="event-summary__event-info">
                <span className="event-summary__event-location">
                  {event.location}
                </span>
              </div>
              <div className="event-summary__event-info">
                <span className="event-summary__event-date">
                  {new Date(event.date).toLocaleString("default", {
                    month: "long",
                  }) +
                    " " +
                    new Date(event.date).toLocaleString("default", {
                      day: "numeric",
                    }) +
                    ", " +
                    new Date(event.date).toLocaleString("default", {
                      year: "numeric",
                    }) +
                    " "}
                </span>
                <span className="event-summary__event-time">
                  {event.startsAt + " "}
                </span>
              </div>
              */}
              <div className="event-summary__action-area">
                {/* <div
                  className="event-summary__action"
                  onClick={() => setShowEventInfo(true)}
                >
                  <InfoIcon className="event-summary__action-icon" />
                  <span>See more</span>
                </div> */}
                {/*  <div
                  className="event-summary__action"
                  onClick={() => setShowLayout(true)}
                >
                  {imageButton?.icon === "MapIcon" && (
                    <MapIcon className="event-summary__action-icon" />
                  )}
                  {imageButton?.icon === "MicroPhoneIcon" && (
                    <MicroPhoneIcon className="event-summary__action-icon" />
                  )}
                  <span>{imageButton?.text}</span>
                </div> */}
              </div>
            </div>
          </div>
          {showLayout && (
            <React.Fragment>
              <div className="popup">
                <CloseIcon
                  className="popup__close"
                  onClick={() => setShowLayout(false)}
                />
                <div className="popup__body">
                  <img
                    src={event.layoutImage}
                    alt=""
                    className="event-summary__popup-image"
                  />
                </div>
              </div>
              <Overlay />
            </React.Fragment>
          )}
          {showEventInfo && (
            <EventPopup
              close={() => setShowEventInfo(false)}
              eventId={event.id}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EventSummary;
