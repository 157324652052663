import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const changeInfo = async (firstName, lastName, mobileNumber, gender) => {
    const response = await axios.put(routes.changeInfo, {
        firstName, lastName, mobileNumber, gender
    }, AXIOS_DEFAULT_CONFIG);
    return response;
}
export default changeInfo;