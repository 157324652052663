import React, { useEffect } from "react";
import qs from "qs";
import * as api from "./../api";

const PresalesRedirect = ({ location = {}, history = {} }) => {
  useEffect(() => {
    const orderId = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_order_id;
    if (!orderId) {
      history.replace("/");
    } else {
      history.replace(`/invitations/receipt/${orderId}`);
    }
  }, []);

  return <></>;
};

export default PresalesRedirect;
