import React from "react";
import { ReactComponent as WalletIcon } from "../../assets/images/wallet.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/users.svg";
import SymplLogo from "./../../assets/images/sympl-logo.png";
import PaymentMethods from "./PaymentMethods";

const PayGroup = ({
  selectedGroup = "",
  handlePayGroupSelect = () => {},
  selectedPayment = { selectedPayment },
  handlePaymentMethodSelect = () => {},
  singleGuest = true,
  skipSelection = false,
  currency = "EGP"
}) => {
  return (
    <div className="payment-methods-list">
      <div
        className={
          "payment-method" +
          (selectedGroup === "pfa" ? " payment-method--selected" : "")
        }
      >
        <label htmlFor="pfa" className="payment-method__body">
          <label className="custom-radio">
            <input
              type="radio"
              checked={selectedGroup === "pfa"}
              onChange={(e) => handlePayGroupSelect(e.target.value)}
              value="pfa"
              name="paymentgroup"
              id="pfa"
            />
            <span className="checkmark"></span>
          </label>
          <div className="payment-method__name">
            Pay For All{" "}
            {/* <Tooltip title="Pay For All" message="Pay for all passes at once" /> */}
            <span className="payment-method__description">
              {" "}
              The Booking Head will be charged for the total amount of passes
              automatically upon approval.
            </span>
          </div>
          <div className="payment-method__icons">
            <WalletIcon className="payment-method__icon payment-method__icon--filled" />
          </div>
        </label>
      </div>
      {selectedGroup === "pfa" && (
        <PaymentMethods
          selectedPayment={selectedPayment}
          handlePaymentMethodSelect={handlePaymentMethodSelect}
          showCard={!skipSelection}
          showLocalPayOptions={skipSelection}
          showFawry={false}
          showValu={false}
          selectableMethods={!skipSelection}
        />
      )}
      {/* {!singleGuest && (
        <React.Fragment>
          <div
            className={
              "payment-method" +
              (selectedGroup === "pwf" ? " payment-method--selected" : "")
            }
          >
            <label htmlFor="pwf" className="payment-method__body">
              <label className="custom-radio">
                <input
                  type="radio"
                  checked={selectedGroup === "pwf"}
                  onChange={(e) => handlePayGroupSelect(e.target.value)}
                  value="pwf"
                  name="paymentgroup"
                  id="pwf"
                />
                <span className="checkmark"></span>
              </label>
              <div className="payment-method__name">
                Pay With Friends{" "}
                <span className="payment-method__description">
                  The Booking Head will be charged for the total amount of their
                  own pass upon approval, and your friends will pay separately
                  via unique links sent to their emails.
                </span>
              </div>

              <div className="payment-method__icons">
                <UsersIcon className="payment-method__icon payment-method__icon--filled" />
              </div>
            </label>
          </div>
          {selectedGroup === "pwf" && (
            <PaymentMethods
              selectedPayment={selectedPayment}
              handlePaymentMethodSelect={handlePaymentMethodSelect}
            />
          )}
        </React.Fragment>
      )} */}
      {/* <div
        className={
          "payment-method" +
          (selectedGroup === "installment" ? " payment-method--selected" : "")
        }
      >
        <label htmlFor="installment" className="payment-method__body">
          <label className="custom-radio">
            <input
              type="radio"
              checked={selectedGroup === "installment"}
              onChange={(e) => {
                handlePayGroupSelect(e.target.value);
                handlePaymentMethodSelect(e.target.value);
              }}
              value="installment"
              name="paymentgroup"
              id="installment"
            />
            <span className="checkmark"></span>
          </label>
          <div className="payment-method__name">
            PAY FOR ALL WITH SYMPL{" "}
            <span className="payment-method__description">
              Split your payment in up to 5 payments with Sympl at 0% interest.
            </span>
          </div>

          <div className="payment-method__icons">
            <img
              className="payment-method__icon payment-method__icon--image"
              src={SymplLogo}
            />
          </div>
        </label>
      </div> */}
    </div>
  );
};

export default PayGroup;
