import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as api from "../../api";
import { ReactComponent as ErrorIcon } from "../../assets/images/input-error.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/cross.svg";
import RegistrationHeader from "./../Utils/RegistrationHeader";

const ForgotPassword = ({
  close = () => {},
  registrationHeader = {},
  handleRegistrationPopup = () => {},
}) => {
  const [formError, setFormError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const schema = yup.object({
    email: yup.string().email("Invalid Email").required("Email is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    setFormError("");
    setSuccess(false);
    setLoading(true);
    api.auth
      .forgotPassword(data.email?.toLowerCase())
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setSuccess(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400 &&
          error?.response?.data?.email
        ) {
          setFormError(error.response.data.email[0]);
        } else {
          setFormError("Something went wrong");
        }
      });
  };
  return (
    <React.Fragment>
      <div className="popup">
        <CloseIcon className="popup__close" onClick={() => close()} />
        <div className="popup__body popup__body--registration">
          {Object.keys(registrationHeader).length !== 0 && (
            <React.Fragment>
              <RegistrationHeader registrationHeader={registrationHeader} />
              <p className="registration-form__lock-paragraph">
                Your passes are locked successfuly
              </p>
              <p className="registration-form__lock-warning">
                Login to be able to checkout
              </p>
            </React.Fragment>
          )}
          <div className="forgot-password-page">
            <form
              className="forgot-password-page__form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h3 className="registration-form__title">
                Forgot your password?
              </h3>
              <p className="forgot-password-page__paragraph">
                Please provide your email and we'll send you instructions on how
                to reset your password.
              </p>
              {formError && (
                <p className="error-message registration-form__form-error">
                  {formError}
                </p>
              )}
              {success && (
                <p className="forgot-password-page__paragraph success-message">
                  Email has been successfully sent.
                </p>
              )}
              <div className="registration-form__input-group">
                <label
                  htmlFor="email"
                  className="registration-form__label registration-form__label--required"
                >
                  Email
                </label>
                <div className="registration-form__input-box">
                  <input
                    type="text"
                    id="email"
                    {...register("email")}
                    className={
                      "registration-form__input" +
                      (errors.email ? " registration-form__input--error" : "")
                    }
                    disabled={loading}
                  />
                  {errors.email && (
                    <ErrorIcon className="registration-form__input-icon" />
                  )}
                </div>
                {errors?.email && (
                  <p className="error-message registration-form__input-error-message">
                    {errors?.email?.message}
                  </p>
                )}
                <p className="registration-form__redirect registration-form__redirect--paragraph">
                  Back to
                  <span
                    className="registration-form__redirect--btn"
                    onClick={() => {
                      close();
                      handleRegistrationPopup("login", true);
                    }}
                  >
                    {" "}
                    Login
                  </span>
                </p>
              </div>
              <button
                type="submit"
                className="btn btn--primary registration-form__btn"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
