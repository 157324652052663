import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, hardcoded_event, routes } from "../../constants";

const list = async () => {
  const response = await axios.get(
    routes.faqsList + (hardcoded_event ? `?event_id=${hardcoded_event}` : ""),
    AXIOS_DEFAULT_CONFIG
  );
  return response;
};
export default list;
