import React from "react";
import Loader from "./Loader";

const PageLoader = ({ title = "", message = "", close = () => {} }) => {
  return (
    <div className="page-loader" onClick={close}>
      <div className="page-loader__body">
        <h3 className="page-loader__title">{title}</h3>
        <p className="page-loader__message">{message}</p>
        <Loader />
      </div>
    </div>
  );
};

export default PageLoader;
