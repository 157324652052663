import React from "react";
import StripePaymentProvider from "./Stripe/StripeProvider";
import PaymentIframe from "./PayByCard";
import PageLoader from "../Utils/PageLoader";
import Overlay from "../Utils/Overlay";

const PaymentService = ({
  type,
  onClose = () => {},
  setLoading = () => {},
  isStripeSetup = false,
  stripeRedirectUrl,
  stripeClientSecret,
  cardPaymentUrl,
  loading,
  onPaymentFail = () => {},
  loadingMessage = "we're getting your payment form ready",
}) => {
  return (
    <React.Fragment>
      {type === "stripe" ? (
        <StripePaymentProvider
          clientSecret={stripeClientSecret}
          close={onClose}
          setLoading={setLoading}
          redirectUrl={stripeRedirectUrl}
          isSetup={isStripeSetup}
          onPaymentFail={onPaymentFail}
        />
      ) : (
        <PaymentIframe
          url={cardPaymentUrl}
          close={() => {
            setLoading(false);
            onClose();
          }}
          setLoading={setLoading}
          loading={loading}
          shouldCheckUrl={type === "paymob"}
        />
      )}
      {loading && (
        <React.Fragment>
          <PageLoader
            title="Hang Tight"
            message={loadingMessage}
            close={onClose}
          />
          <Overlay darkTheme={true} handleOnClick={onClose} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PaymentService;
