import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as api from "../../api";
import { ReactComponent as CloseIcon } from "../../assets/images/cross.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/input-error.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/input-success.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input/mobile";
import RegistrationHeader from "../Utils/RegistrationHeader";
import Select from "react-select";
import { customStyles } from "./../../utils/selectStyles";

const Register = ({
  close = () => {},
  handleRegistrationPopup = () => {},
  registrationHeader = {},
  setNewUser = () => {},
}) => {
  const [formError, setFormError] = useState("");
  const genderOptions = [
    { label: "Male", value: "m" },
    { label: "Female", value: "f" },
  ];
  yup.addMethod(yup.string, "phone", function (errorMessage) {
    return this.test("test-phone", errorMessage, function (value) {
      const { path, createError } = this;
      if (!value) {
        return false;
      }
      if (isValidPhoneNumber(value)) {
        return true;
      } else {
        createError({ path, message: errorMessage });
        return false;
      }
    });
  });
  const schema = yup.object({
    firstName: yup.string().required("First name is Required"),
    lastName: yup.string().required("Last name is Required"),
    email: yup.string().email("Invalid Email").required("Email is required"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(/^(?!.* )/, "Password can't contain spaces")
      .required("Password is required"),
    phone: yup.string().phone("Invalid number").required("Phone is required"),
    gender: yup
      .object()
      .shape({
        value: yup.string().required("Please select gender"),
        label: yup.string().required("Please select gender"),
      })
      .nullable()
      .required("Please select gender"),
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const onSubmit = async (data) => {
    setFormError("");
    api.auth
      .register(
        data.email,
        data.firstName,
        data.lastName,
        data.gender.value,
        data.phone,
        data.password
      )
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          close();
          setNewUser({
            message: "We’ve sent you a confirmation link on ",
            messageLine2:
              ", verify your link and come back to this page to sign in!",
            email: response.data.email,
          });
          handleRegistrationPopup("login", true);
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          let err = "";
          let obj = error.response.data;
          if (obj.nonFieldErrors) {
            err = obj.nonFieldErrors[0];
          } else if (obj.email) {
            err = obj.email;
          }
          setFormError(err);
        } else {
          setFormError("Something went wrong");
        }
      });
  };
  return (
    <React.Fragment>
      <div className="popup">
        <CloseIcon className="popup__close" onClick={() => close()} />
        <div className="popup__body popup__body--registration">
          {Object.keys(registrationHeader).length !== 0 && (
            <React.Fragment>
              <RegistrationHeader registrationHeader={registrationHeader} />
              <p className="registration-form__lock-paragraph">
                Your passes are locked successfuly
              </p>
              <p className="registration-form__lock-warning">
                Register to be able to checkout
              </p>
            </React.Fragment>
          )}
          <form
            className={
              "registration-form registration-form--register" +
              (Object.keys(registrationHeader).length !== 0
                ? " registration-form--timed"
                : "")
            }
            onSubmit={handleSubmit(onSubmit)}
          >
            <h3 className="registration-form__title">Register</h3>
            {formError && (
              <p className="error-message registration-form__form-error">
                {formError}
              </p>
            )}
            <div className="registration-form__input-group--double">
              <div className="registration-form__input-group">
                <label
                  htmlFor="firstName"
                  className="registration-form__label registration-form__label--required"
                >
                  First Name
                </label>
                <div className="registration-form__input-box">
                  <input
                    type="text"
                    id="firstName"
                    {...register("firstName")}
                    className={
                      "registration-form__input" +
                      (errors.firstName
                        ? " registration-form__input--error"
                        : "")
                    }
                  />
                  {errors.firstName && (
                    <ErrorIcon className="registration-form__input-icon" />
                  )}
                  {!errors.firstName && dirtyFields.firstName && (
                    <SuccessIcon className="registration-form__input-icon" />
                  )}
                </div>
                {errors?.firstName && (
                  <p className="error-message registration-form__input-error-message">
                    {errors?.firstName?.message}
                  </p>
                )}
              </div>
              <div className="registration-form__input-group">
                <label
                  htmlFor="lastName"
                  className="registration-form__label registration-form__label--required"
                >
                  Last Name
                </label>
                <div className="registration-form__input-box">
                  <input
                    type="text"
                    id="lastName"
                    {...register("lastName")}
                    className={
                      "registration-form__input" +
                      (errors.lastName
                        ? " registration-form__input--error"
                        : "")
                    }
                  />
                  {errors.lastName && (
                    <ErrorIcon className="registration-form__input-icon" />
                  )}
                  {!errors.lastName && dirtyFields.lastName && (
                    <SuccessIcon className="registration-form__input-icon" />
                  )}
                </div>
                {errors?.lastName && (
                  <p className="error-message registration-form__input-error-message">
                    {errors?.lastName?.message}
                  </p>
                )}
              </div>
            </div>
            <div className="registration-form__input-group--double">
              <div className="registration-form__input-group">
                <label
                  htmlFor="email"
                  className="registration-form__label registration-form__label--required"
                >
                  Email
                </label>
                <div className="registration-form__input-box">
                  <input
                    type="text"
                    id="email"
                    {...register("email")}
                    className={
                      "registration-form__input" +
                      (errors.email ? " registration-form__input--error" : "")
                    }
                  />
                  {errors.email && (
                    <ErrorIcon className="registration-form__input-icon" />
                  )}
                  {!errors.email && dirtyFields.email && (
                    <SuccessIcon className="registration-form__input-icon" />
                  )}
                </div>
                {errors?.email && (
                  <p className="error-message registration-form__input-error-message">
                    {errors?.email?.message}
                  </p>
                )}
              </div>
              <div className="registration-form__input-group">
                <label
                  htmlFor="password"
                  className="registration-form__label registration-form__label--required"
                >
                  Password
                </label>
                <div className="registration-form__input-box">
                  <input
                    type="password"
                    id="password"
                    {...register("password")}
                    className={
                      "registration-form__input" +
                      (errors.password
                        ? " registration-form__input--error"
                        : "")
                    }
                  />
                  {errors.password && (
                    <ErrorIcon className="registration-form__input-icon" />
                  )}
                  {!errors.password && dirtyFields.password && (
                    <SuccessIcon className="registration-form__input-icon" />
                  )}
                </div>
                {errors?.password && (
                  <p className="error-message registration-form__input-error-message">
                    {errors?.password?.message}
                  </p>
                )}
              </div>
            </div>
            <div className="registration-form__input-group--double">
              <div className="registration-form__input-group">
                <label
                  htmlFor="phone"
                  className="registration-form__label registration-form__label--required"
                >
                  Phone
                </label>
                <div className="registration-form__input-box">
                  <Controller
                    control={control}
                    name="phone"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <PhoneInput
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        defaultCountry="EG"
                        international={true}
                        className={errors.phone ? "PhoneInput--error" : ""}
                      />
                    )}
                  />
                  {errors.phone && (
                    <ErrorIcon className="registration-form__input-icon" />
                  )}
                  {!errors.phone && dirtyFields.phone && (
                    <SuccessIcon className="registration-form__input-icon" />
                  )}
                </div>
                {errors?.phone && (
                  <p className="error-message registration-form__input-error-message">
                    {errors?.phone?.message}
                  </p>
                )}
              </div>
              <div className="registration-form__input-group">
                <label
                  htmlFor="gender"
                  className="registration-form__label registration-form__label--required"
                >
                  Gender
                </label>
                <div className="registration-form__input-box">
                  <Controller
                    control={control}
                    name="gender"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        options={genderOptions}
                        styles={
                          errors.gender
                            ? customStyles("error")
                            : customStyles("default")
                        }
                        className="registration-form__select-input"
                        classNamePrefix="registration-form__select-input"
                      />
                    )}
                  />
                  {/* {errors.gender && (
                    <ErrorIcon className="registration-form__input-icon" />
                  )}
                  {!errors.gender && dirtyFields.gender && (
                    <SuccessIcon className="registration-form__input-icon" />
                  )} */}
                </div>
                {errors?.gender && (
                  <p className="error-message registration-form__input-error-message">
                    {errors?.gender?.value?.message}
                  </p>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="btn btn--primary registration-form__btn"
            >
              Create Account
            </button>
            <p className="registration-form__redirect registration-form__redirect--paragraph">
              Already have an account?
              <span
                className="registration-form__redirect--btn"
                onClick={() => {
                  close();
                  handleRegistrationPopup("login", true);
                }}
              >
                {" "}
                Login
              </span>
            </p>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Register;
