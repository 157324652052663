import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/images/cross.svg";
import Overlay from "./Overlay";

const TechnicalDifficulties = ({
  message = "Sorry, we're currently experiencing technical difficulties! Please try again later",
  onClose = () => {},
}) => {
  return (
    <React.Fragment>
      <div className="popup">
        <CloseIcon className="popup__close" onClick={() => onClose()} />
        <div className="popup__body">
            <p className="empty-page__message">{message}</p>
          </div>
      </div>
      <Overlay darkTheme/>
    </React.Fragment>
  );
};

export default TechnicalDifficulties;
