import React, { useState, useEffect } from "react";
import OrderCard from "../components/Orders/OrderCard";
import * as api from "../api";
import { hardcoded_event } from "../constants";

const MyOrders = ({
  user = {},
  setShowLogin = () => {},
  userLoading = false,
}) => {
  const [orders, setOrders] = useState([]);
  const [reservationOrders, setReservationOrders] = useState([]);
  const [ticketOrders, setTicketOrders] = useState([]);
  const [loadingTicketOrders, setLoadingTicketOrders] = useState(true);
  const [loadingReservations, setLoadingReservations] = useState(false);
  const [nextAction, setNextAction] = useState(null);
  const statusMapper = {
    pending: "Pending Approval",
    rejected: "Rejected",
    rejected_ratio: "Rejected",
    paid: "Paid",
    approved: "Approved",
    partially_paid: "Pending friends payment",
    expired: "Expired",
    failed: "Payment Failed",
    canceled: "Booking Cancelled",
    cancelled: "Booking Cancelled",
    balance_due: "Balance Due",
    order_failed: "Order Failed",
    repayment: "Repayment needed",
  };
  const statusTypeMapper = {
    pending: "pending",
    rejected: "error",
    rejected_ratio: "error",
    paid: "success",
    approved: "pending",
    partially_paid: "pending",
    expired: "error",
    failed: "error",
    canceled: "error",
    cancelled: "error",
    balance_due: "pending",
    order_failed: "error",
    repayment: "pending",
  };

  useEffect(() => {
    if (Object.keys(user).length !== 0 && !userLoading) {
      setShowLogin(false);
      setNextAction(null);
      fetchOrders();
    } else if (Object.keys(user).length === 0 && !userLoading) {
      setShowLogin(true);
      setNextAction("loadOrders");
    }
  }, [user, userLoading]);

  useEffect(() => {
    if (Object.keys(user).length !== 0 && nextAction !== null) {
      handleNextAction(nextAction);
    }
  }, [user, nextAction]);

  const handleNextAction = (action) => {
    setNextAction(null);
    if (action === "loadOrders" && Object.keys(user).length !== 0) {
      fetchOrders();
    }
  };

  useEffect(() => {
    if (!loadingReservations && !loadingTicketOrders) {
      let ordersCopy = [];
      let ticketsCopy = [...ticketOrders];
      let reservationsCopy = [...reservationOrders];
      ordersCopy = reservationsCopy.concat(ticketsCopy);
      ordersCopy.sort((a, b) => b.createdAt - a.createdAt);
      setOrders(ordersCopy);
    }
  }, [
    ticketOrders,
    reservationOrders,
    loadingReservations,
    loadingTicketOrders,
  ]);

  const fetchOrders = () => {
    let reservations = [];
    let tickets = [];
    if (hardcoded_event === null) {
      api.orders
        .list()
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            tickets = response.data.results.map((order) => {
              let status = order.paymentStatus;
              return {
                orderId: order.pk,
                event: {
                  startsAt: new Date(order.event.startsAt).toLocaleDateString(
                    "en-uk"
                  ),
                  name: order.event.name,
                  location: order.event.location,
                },
                link: `/orders/${order.pk}`,
                price: order.amount / 100,
                purchaseDate: new Date(order.createdAt).toLocaleDateString(
                  "en-uk"
                ),
                createdAt: new Date(order.createdAt).getTime(),
                thumbnail: order.event.thumbnail,
                status: status,
                statusText: statusMapper[status],
                statusType: statusTypeMapper[status],
                ticketCount: order.ticketItems.reduce(
                  (sum, current) => sum + current.quantity,
                  0
                ),
                currency: order.currency,
              };
            });
            setLoadingTicketOrders(false);
            setTicketOrders(tickets);
          }
        })
        .catch(() => {
          setLoadingTicketOrders(false);
        });
    } else {
      setLoadingTicketOrders(false);
    }
    api.reservations
      .getReservations()
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          reservations = response.data.results.map((item) => {
            let status = item.reservation.status;
            if (status === "re-payment") {
              status = "repayment";
            } else if (!item?.paymentStatus) {
              //moto failed
              if (status === "invitation_pending") {
                status = "order_failed";
              }
            } else if (
              status !== "paid" &&
              status !== "partially_paid" &&
              status !== "cancelled" &&
              status !== "canceled" &&
              status !== "rejected" &&
              status !== "rejected_ratio"
            ) {
              if (
                item?.paymentStatus === "pending" &&
                status === "pending" &&
                item?.skipMoto
              ) {
                status = "pending";
              }
              // no moto flow approved res
              else if (
                item?.paymentStatus === "pending" &&
                status === "approved" &&
                item?.skipMoto
              ) {
                status = "balance_due";
              } else if (
                item.paymentStatus === "failed" ||
                (item?.paymentStatus === "pending" && status === "approved")
              ) {
                status = "failed";
              }
            }

            if (
              item?.paidAmount < item?.amount &&
              (item?.paidAmount > 0 || item?.reservation?.status === "approved")
            ) {
              if (status !== "repayment") {
                if (item?.paymentStatus === "failed") {
                  status = "failed";
                }
                //Partially paid, show popup to pay
                else {
                  status = "balance_due";
                }
              }
            }
            return {
              orderId: item.pk,
              event: {
                startsAt: new Date(
                  item.reservation.event.startsAt
                ).toLocaleDateString("en-uk"),
                name: item.reservation.event.name,
                location: item.reservation.event.location,
              },
              link: `/orders/${item.pk}`,
              createdAt: new Date(item.createdAt).getTime(),
              thumbnail: item.reservation.event.thumbnail,
              ticketCount: item.items.length,
              price: item.amount / 100,
              purchaseDate: new Date(item.createdAt).toLocaleDateString(
                "en-uk"
              ),
              status: status,
              statusText: statusMapper[status],
              statusType: statusTypeMapper[status],
              currency: item.currency,
            };
          });
          setReservationOrders(reservations);
          setLoadingReservations(false);
        }
      })
      .catch(() => {
        setLoadingReservations(false);
      });
  };

  return (
    <div className="myorders">
      <h2 className="page-title">My Orders</h2>
      {orders.map((order, index) => (
        <React.Fragment key={index}>
          <OrderCard order={order} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default MyOrders;
