import React, { useState, useEffect } from "react";
import CountDown from "./CountDown";
import { ReactComponent as Lock } from "../../assets/images/lock.svg";

const RegistrationHeader = ({ registrationHeader = {} }) => {
  const [size, setSize] = useState(100);

  useEffect(() => {
    let wrapper = document.getElementById("wrapper");
    setSize(wrapper.clientWidth);
    window.addEventListener("resize", resizeTimer, false);
    return () => {
      window.removeEventListener("resize", resizeTimer, false);
    };
  }, []);

  const resizeTimer = () => {
    let wrapper = document.getElementById("wrapper");
    setSize(wrapper.clientWidth);
  };

  return (
    <div className="registration-header">
      <div className="registration-header__info">
        <div className="registration-header__tickets">
          <Lock className="registration-header__icon" />{" "}
          {registrationHeader.ticketCount} Tickets
        </div>
        <h3 className="registration-header__title">
          {registrationHeader.eventTitle}
        </h3>
      </div>
      <div className="registration-header__timer" id="wrapper">
      <CountDown expiry={registrationHeader?.expiry}/>
      </div>
    </div>
  );
};

export default RegistrationHeader;
