import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const register = async (email, firstName, lastName, gender, mobileNumber, password) => {
    const response = await axios.post(routes.register, {
        email, firstName, lastName, gender, mobileNumber, password
    }, AXIOS_DEFAULT_CONFIG);
    return response;
}
export default register;