import React, { useState, useEffect } from "react";
import PaymentMethods from "./../Utils/PaymentMethods";
import CTAPopup from "./CTAPopup";
import CheckoutDetails from "./CheckoutDetails";
import TermsAndConditions from "../Utils/TermsAndConditions";
import TermsOfPurchase from "../Utils/TermsOfPurchase";
import PayGroup from "./../Utils/PayGroup";
import TechnicalDifficulties from "../Utils/TechnicalDifficulties";

const Pay = ({
  user = {},
  event = {},
  tickets = {},
  expiry = null,
  handlePaymentSubmit = () => {},
  selectedAddons = {},
  subtotal = 0,
  setSubtotal = () => {},
  showReceipt = false,
  setShowReceipt = () => {},
  grandTotal = 0,
  setGrandTotal = () => {},
  handleStepClick = () => {},
  cancelOrder = () => {},
  tempCart = {},
  showEditAddons = false,
  editGuests = false,
  termsAndConditions = {},
  setTermsAndConditions = () => {},
  currency,
  skipPayment = false,
  commission = 0
}) => {
  const [selectedPayment, setSelectedPayment] = useState("");
  const [totalTickets, setTotalTickets] = useState(0);
  const [payGroup, setPayGroup] = useState(skipPayment ? "pfa" : "");
  const [errors, setErrors] = useState({});
  const [showTechnicalDifficulties, setShowTechnicalDifficulties] =
    useState(false);

  useEffect(() => {
    getCartTotal();
  }, []);

  const getCartTotal = () => {
    let sum = 0;
    let price = 0;
    let cart = event.type === "full" ? { ...tempCart } : { ...tickets };
    for (const ticket in cart) {
      sum += cart[ticket].quantity;
      price += cart[ticket].quantity * cart[ticket].price;
    }
    for (const addon in selectedAddons) {
      price += selectedAddons[addon].price * selectedAddons[addon].count;
    }
    setTotalTickets(sum);
    setSubtotal(price);
  };

  const handlePaymentMethodSelect = (name) => {
    setSelectedPayment(name);
  };

  const handlePayGroupSelect = (name) => {
    setPayGroup(name);
  };

  const handleSubmit = () => {
    if (areFormsValid()) {
      handlePaymentSubmit(selectedPayment, payGroup !== "" ? payGroup : null);
    }
  };

  const handleTermCheck = (value, index) => {
    let termsCopy = [...termsAndConditions["terms"]];
    let termCopy = { ...termsCopy[index] };
    termCopy.checked = value;
    termsCopy[index] = termCopy;
    setTermsAndConditions((current) => {
      return { ...current, terms: termsCopy };
    });
  };

  const areFormsValid = () => {
    let errs = { ...errors };

    let terms = termsAndConditions["terms"];

    if (terms && terms.filter((item) => !item.checked)?.length) {
      errs.terms = "You must agree to all Terms and Conditions.";
    } else {
      delete errs.terms;
    }
    if (
      (selectedPayment === "" && !skipPayment) ||
      (event.type === "full" && payGroup === "")
    ) {
      errs.payment = "Please select payment method";
    } else {
      delete errs.payment;
    }
    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = document.getElementsByClassName("error-message")[0];
      error.scrollIntoView();
    }
  }, [errors]);

  if (showTechnicalDifficulties) {
    return (
      <TechnicalDifficulties
        onClose={() => setShowTechnicalDifficulties(false)}
      />
    );
  }

  return (
    <React.Fragment>
      <div className="event-page__body event-page__body--checkout">
        <div className="checkout__payment">
          <h4 className="checkout__subtitle checkout__subtitle--required">
            {user?.firstName ? user.firstName + ", " : ""}Complete your order
          </h4>
          <p className="checkout__paragraph">Select your Payment Method</p>
          {errors.payment && <p className="error-message">{errors.payment}</p>}
          {event.type === "full" ? (
            <React.Fragment>
              <PayGroup
                selectedGroup={payGroup}
                handlePayGroupSelect={handlePayGroupSelect}
                selectedPayment={selectedPayment}
                handlePaymentMethodSelect={handlePaymentMethodSelect}
                singleGuest={
                  Object.keys(tempCart).length === 1 &&
                  tempCart[Object.keys(tempCart)[0]].quantity === 1
                }
                skipSelection={skipPayment}
                currency={currency}
              />
            </React.Fragment>
          ) : (
            <PaymentMethods
              selectedPayment={selectedPayment}
              handlePaymentMethodSelect={handlePaymentMethodSelect}
            />
          )}
          <TermsAndConditions
            termsAndConditions={termsAndConditions}
            handleTermCheck={handleTermCheck}
            error={errors?.terms || ""}
          />
        </div>
        <CheckoutDetails
          subtotal={subtotal}
          showReceipt={showReceipt}
          setShowReceipt={setShowReceipt}
          grandTotal={grandTotal}
          event={event}
          tickets={event.type === "full" ? tempCart : tickets}
          selectedAddons={selectedAddons}
          setGrandTotal={setGrandTotal}
          totalTickets={totalTickets}
          showEditAddons={showEditAddons}
          handleStepClick={handleStepClick}
          cancelOrder={cancelOrder}
          editGuests={editGuests}
          currency={currency}
          commission={commission}
        />
      </div>
      {/* {loading ? (
        <CTAPopup
          actionHandler={null}
          variation="no-action"
          displayMessage=" "
          showOverlay={true}
          loading={loading}
        />
      ) : ( */}
      <CTAPopup
        actionHandler={() => {
          handleSubmit();
          /* setShowTechnicalDifficulties(true); */
        }}
        title=""
        variation="single"
        text={"Book"}
        expiry={expiry}
      />
      {/* )} */}
    </React.Fragment>
  );
};

export default Pay;
