import React, { useEffect, useState } from "react";
import { VAT, entertainmentTax } from "./../../constants";
import { formatNumbers, formatNumbers0 } from "./../../utils/formatNumbers";

const CheckoutReceipt = ({
  tickets = [],
  subtotal = 0,
  addons = [],
  setGrandTotal = () => {},
  showReceipt = true,
  grandTotal = "",
  isFoc = null,
  currency = "",
  discount = 0,
  commission = 0,
}) => {
  let [total, setTotal] = useState(0);
  let [commissionValue, setCommissionValue] = useState(0);
  let [enterTaxValue, setEnterTaxValue] = useState(0);

  useEffect(() => {
    let total = 0;
    let comm = subtotal * (commission / 100);
    let entTax = subtotal * entertainmentTax;
    let proc_fees = 0;
    total = subtotal + comm + proc_fees - discount /*+ entTax */;
    let v = total; /* + total * VAT */
    setGrandTotal(v);
    setTotal(total);
    setEnterTaxValue(entTax);
    setCommissionValue(comm);
  }, [subtotal]);

  return (
    <div className="checkout__receipt">
      {showReceipt && (
        <table className="receipt-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Sum</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket, index) => (
              <tr key={index}>
                <td>{ticket.name}</td>
                <td>{ticket.quantity}</td>
                <td>
                  {isFoc === true
                    ? "Free"
                    : formatNumbers(ticket.price * ticket.quantity, true) +
                      ` ${currency}`}
                </td>
              </tr>
            ))}
            {addons.map((addon, index) => (
              <tr key={index}>
                <td>{addon.name}</td>
                <td>
                  {addon.type !== "bulk" && addon.type !== "fixed"
                    ? addon.count
                    : ""}
                </td>
                <td>
                  {formatNumbers(addon.count * addon.price)} {currency}
                </td>
              </tr>
            ))}

            {
              !isFoc && +commissionValue > 0 && (
                <tr>
                  <td>Service Charges</td>
                  <td></td>
                  <td>
                    {formatNumbers(commissionValue, true)} {currency}
                  </td>
                </tr>
              )

              /*
            <tr>
              <td>Entertainment Tax</td>
              <td></td>
              <td>{formatNumbers(enterTaxValue)} EGP</td>
            </tr>
            <tr>
              <td>VAT</td>
              <td></td>
              <td>{formatNumbers(total * VAT)} EGP</td>
            </tr> */
            }
            {/*currency === "EGP" && (
              <tr>
                <td>Processing Fees</td>
                <td></td>
                <td>{formatNumbers(commissionValue*1.2133)} EGP</td>
              </tr>
            )*/}

            {discount > 0 && (
              <tr>
                <td>Discount</td>
                <td></td>
                <td>
                  - {formatNumbers(discount, true)} {currency}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div className="checkout__grand-total">
        <h3 className="checkout__grand-total-title">Order total</h3>
        <div className="checkout__grand-total-value">
          <p>
            {isFoc === true
              ? "Free"
              : formatNumbers(grandTotal, true) + ` ${currency}`}
          </p>
        </div>
        {/*   <div
          className={
            "checkout__receipt-toggle" +
            (showReceipt ? " checkout__receipt-toggle--opened" : "")
          }
          onClick={() => setShowReceipt(!showReceipt)}
        >
          {formatNumbers(grandTotal)} EGP
          {showReceipt ? (
            <DropdownIconRed className="checkout__receipt-icon" />
          ) : (
            <DropdownIcon className="checkout__receipt-icon" />
          )}
        </div> */}
      </div>
    </div>
  );
};

export default CheckoutReceipt;
