import React, { useState, useEffect } from "react";
import Overlay from "./Overlay";
import { ReactComponent as CloseIcon } from "../../assets/images/cross.svg";
import CountDown from "./CountDown";
import { ReactComponent as Exclamation } from "../../assets/images/exclamation-red.svg";
import { useIsMobileScreenSize } from "../../utils/hooks";

const ExpiryPopup = ({
  title = "",
  message = "",
  handleBtn1Click = () => {},
  handleBtn2Click = () => {},
  btn1 = "",
  btn2 = "",
  closable = false,
  close = () => {},
  expiry = null,
  actionMessage = "",
  variant = "",
}) => {
  const isMobile = useIsMobileScreenSize();

  return (
    <React.Fragment>
      <div className="popup">
        {closable && <CloseIcon className="popup__close" onClick={close} />}
        <div className="popup__body expiry-popup__body">
          {!isMobile && title && (
            <div className="expiry-popup__header">
              <h3 className="expiry-popup__title">{title}</h3>
              <div className="registration-header__timer" id="wrapper">
                <CountDown expiry={expiry} />
              </div>
            </div>
          )}
          <Exclamation className="expiry-popup__icon" />
          {message && (
            <p
              className={
                "expiry-popup__message" +
                (variant === "error" ? " expiry-popup__message--error" : "")
              }
            >
              {message}
            </p>
          )}
          <p className="expiry-popup__action-message">{actionMessage}</p>
          {isMobile && title && (
            <div
              className="registration-header__timer registration-header__timer--mobile"
              id="wrapper"
            >
              <CountDown expiry={expiry} />
            </div>
          )}
          {btn1 && (
            <button
              className="btn btn--primary expiry-popup__btn expiry-popup__btn--1"
              onClick={handleBtn1Click}
            >
              {btn1}
            </button>
          )}
          {btn2 && (
            <button
              className="btn btn--secondary expiry-popup__btn  expiry-popup__btn--2"
              onClick={handleBtn2Click}
            >
              {btn2}
            </button>
          )}
        </div>
      </div>
      <Overlay darkTheme={true} />
    </React.Fragment>
  );
};

export default ExpiryPopup;
