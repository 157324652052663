import React from "react";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

const TermsAndConditions = ({
  termsAndConditions = {},
  handleTermCheck = () => {},
  error = "",
}) => {
  return (
    <div className="terms">
      {termsAndConditions?.terms?.length && (
        <p className="terms__title">Terms {"&"} conditions</p>
      )}
      {error && <p className="error-message">{error}</p>}
      {termsAndConditions?.title && (
        <div className="terms__body terms__body--title">
          {parse(
            DOMPurify.sanitize(termsAndConditions.title, {
              USE_PROFILES: { html: true },
            })
          )}
        </div>
      )}
      {termsAndConditions?.terms?.map((item, index) => (
        <React.Fragment key={index}>
          {item.content && (
            <div className="terms__body">
              {parse(
                DOMPurify.sanitize(item.content, {
                  USE_PROFILES: { html: true },
                })
              )}
            </div>
          )}
          <label className="custom-checkbox custom-checkbox--square terms__btn">
            <input
              type="checkbox"
              checked={item.checked}
              onChange={(e) => handleTermCheck(e.target.checked, index)}
            />
            <span className="checkmark"></span>
            {item.term}
          </label>
        </React.Fragment>
      ))}
    </div>
  );
};

export default TermsAndConditions;
