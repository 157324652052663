import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const forgotPassword = async (email) => {
    const response = await axios.post(routes.forgotPassword, {
        email
    }, AXIOS_DEFAULT_CONFIG);
    return response;
}
export default forgotPassword;