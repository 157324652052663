import React, { useState, useEffect, useRef } from "react";
import CheckoutReceipt from "../Receipt/CheckoutReceipt";
import { ReactComponent as KioskIcon } from "../../assets/images/kiosk.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/order-success-checkmark.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/input-error.svg";
import { ReactComponent as DownlaodIcon } from "../../assets/images/download.svg";
import { ReactComponent as ClockIcon } from "../../assets/images/clock.svg";
import { ReactComponent as CancelIcon } from "../../assets/images/cross.svg";
import VisaIcon from "../../assets/images/visa-icon.svg";
import MasterCardIcon from "../../assets/images/mastercard-icon.svg";
import SymplIcon from "./../../assets/images/sympl-logo.png";
import * as api from "../../api";
import { options as timeOptions } from "../../utils/timeFormat";
import { options as dateOptions } from "../../utils/dateFormat";
import { formatNumbers } from "../../utils/formatNumbers";
import ShareAndDirections from "../Utils/ShareAndDirections";
import { Link } from "react-router-dom";
import CTAPopup from "../Event/CTAPopup";
import { useHistory } from "react-router-dom";
import ExpiryPopup from "../Utils/ExpiryPopup";
import PaymentConfirmationEventBanner from "./../Event/PaymentConfirmationEventBanner";
import { hardcoded_event, min_payment } from "./../../constants";
import PaymentSelectorPopup from "../Utils/PaymentSelectorPopup";
import PaymentService from "./PaymentService";
import TechnicalDifficulties from "../Utils/TechnicalDifficulties";
import PageLoader from "../Utils/PageLoader";

const PaymentConfirmation = ({
  match,
  user = {},
  setShowLogin = () => {},
  userLoading = false,
}) => {
  const [tickets, setTickets] = useState([]);
  const [addons, setAddons] = useState([]);
  const [event, setEvent] = useState({});
  const [status, setStatus] = useState({});
  const [grandTotal, setGrandTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [ticketCount, setTicketCount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [billReference, setBillReference] = useState("");
  const [ticketsFile, setTicketsFile] = useState(null);
  const [showActionBar, setShowActionBar] = useState(false);
  const [expired, setExpired] = useState(false);
  const [expiry, setExpiry] = useState(null);
  const [cartToken, setCartToken] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [paymentData, setPaymentData] = useState({});
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showPaymentSelector, setShowPaymentSelector] = useState(false);
  const [isPwf, setIsPwf] = useState(false);
  const [currency, setCurrency] = useState();
  const [customAmount, setCustomAmount] = useState();
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [showTechnicalDifficulties, setShowTechnicalDifficulties] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [discount, setDiscount] = useState(0);
  const [paymentError, setPaymentError] = useState("");
  const [customer, setCustomer] = useState({});
  const [commission, setCommission] = useState(0);
  const failed = useRef(null);
  const history = useHistory();
  const statusMapper = {
    pending: "Pending Approval",
    rejected: "Rejected",
    rejected_ratio: "Rejected",
    paid: "Paid",
    approved: "Approved",
    partially_paid: "Pending friends payment",
    expired: "Expired",
    failed: "Payment Failed",
    cancelled: "Booking Cancelled",
    canceled: "Booking Cancelled",
    friend_pay_fail: "Pending friends payment",
    balance_due: "Balance Due",
    order_failed: "Order Failed",
    repayment: "Repayment needed",
  };
  const statusTypeMapper = {
    pending: "pending",
    rejected: "error",
    rejected_ratio: "error",
    paid: "success",
    approved: "pending",
    partially_paid: "pending",
    expired: "error",
    failed: "error",
    canceled: "error",
    cancelled: "error",
    friend_pay_fail: "pending",
    balance_due: "pending",
    order_failed: "error",
    repayment: "pending",
  };

  const timeOutRef = useRef(null);

  const showPaymentOptions = currency === "USD";
  const showSplitPaymentOption =
    +remainingBalance >= min_payment * 2 && currency === "USD";

  useEffect(() => {
    getCartTotal();
  }, [tickets, addons]);

  useEffect(() => {
    fetchOrder();
    return () => {
      if (timeOutRef?.current) {
        clearInterval(timeOutRef.current);
      }
    };
  }, []);

  const fetchOrder = () => {
    if (hardcoded_event !== null) {
      fetchReservationOrder();
    } else {
      fetchTicketOrder();
    }
  };

  const fetchReservationOrder = () => {
    api.reservations
      .getReservationById(match.params.id)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setCurrency(response?.data?.currency);
          setCommission(response?.data?.region?.commissionRate);
          setIsPwf(response?.data?.isPwf);
          let eventObj = {
            location: response.data.reservation.event.location,
            locationLink: response.data.reservation.event.locationLink,
            title: response.data.reservation.event.name,
            image: response.data.reservation.event.banner,
            time: new Date(
              response.data.reservation.event.startsAt
            ).toLocaleTimeString([], timeOptions),
            date: response.data.reservation.event.startsAt,
            type: response.data.reservation.event.reservationProcessType,
            id: response?.data?.reservation?.event?.pk,
          };
          let addonsArray = /* response.data.addonItems.map((addon) => {
                return {
                  name: addon.name,
                  count: addon.quantity,
                  price: addon.addon.price / 100,
                };
              }) */ [];
          let ticketsArray = response.data.items.map((item) => {
            return {
              name:
                item.customer.firstName +
                " " +
                item.customer.lastName +
                " (" +
                item.name +
                ")",
              quantity: 1,
              price: item.ticketTier.price / 100,
              currency: response?.data?.currency,
            };
          });
          let status = response.data?.reservation?.status;
          if (status === "re-payment") {
            status = "repayment";
          }
          //moto failed
          else if (
            !response?.data?.paymentStatus &&
            status === "invitation_pending"
          ) {
            status = "order_failed";
          } else if (
            status !== "paid" &&
            status !== "partially_paid" &&
            status !== "cancelled" &&
            status !== "canceled" &&
            status !== "rejected" &&
            status !== "rejected_ratio"
          ) {
            // no moto flow pending res
            if (
              response?.data?.paymentStatus === "pending" &&
              status === "pending" &&
              response?.data?.skipMoto
            ) {
              status = "pending";
            }
            // no moto flow approved res
            else if (
              response?.data?.paymentStatus === "pending" &&
              status === "approved" &&
              response?.data?.skipMoto
            ) {
              status = "balance_due";
            } else if (
              response?.data?.paymentStatus === "failed" ||
              (response?.data?.paymentStatus === "pending" &&
                status === "approved")
            ) {
              status = "failed";
            }
          } else if (
            status === "partially_paid" &&
            response?.data?.paymentStatus !== "paid" &&
            response?.data?.isPwf &&
            (user?.pk !== response?.data?.reservation?.reservationHead?.pk ||
              (user?.pk === response?.data?.reservation?.reservationHead?.pk &&
                user?.pk !== response?.data?.customer?.pk))
          ) {
            status = "friend_pay_fail";
          }
          if (timeOutRef !== null) {
            clearInterval(timeOutRef.current);
          }
          setRemainingBalance(
            (response?.data?.amount - response?.data?.paidAmount) / 100
          );
          if (
            response?.data?.paidAmount < response?.data?.amount &&
            (response?.data?.paidAmount > 0 ||
              response?.data?.reservation?.status === "approved")
          ) {
            if (status !== "repayment") {
              if (response?.data?.paymentStatus === "failed") {
                status = "failed";
              }
              //Partially paid, show popup to pay
              else {
                status = "balance_due";
              }
            }
          }
          if (
            status === "failed" ||
            status === "repayment" ||
            status === "balance_due"
          ) {
            setShowActionBar(true);
            if (response?.data?.cart) {
              let newExpiry = new Date(response.data.cart.expiresAt).getTime();
              timeOutRef.current = setInterval(() => {
                if (newExpiry - new Date().getTime() <= 0) {
                  setExpired(true);
                  setPaymentData({});
                  setPaymentLoading(false);
                }
              }, 1000);
              setExpiry(newExpiry);
            } else {
              setExpiry(null);
              setExpired(false);
            }
          }
          if (response?.data?.cartToken) {
            setCartToken(response.data.cartToken);
          }
          setPaidAmount(response?.data?.paidAmount / 100);
          setEvent(eventObj);
          setAddons(addonsArray);
          setStatus({
            text: statusMapper[status],
            type: statusTypeMapper[status],
            original: status,
            reservation: response.data.reservation.status,
          });
          setTickets(ticketsArray);
          setPaymentMethod(response.data.paymentType);
          setReservationId(response.data.reservation.code);
          setOrderId(response.data.pk);
          setLoading(false);
          setDiscount(response?.data?.discount / 100 || 0);
          setCustomer(response?.data?.customer);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404 && !failed.current) {
          failed.current = true;
          fetchTicketOrder();
        } else {
          setLoading(false);
        }
      });
  };

  const fetchTicketOrder = () => {
    api.cart
      .getOrderItems(match.params.id)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setCurrency(response?.data?.currency);
          setCommission(response?.data?.region?.commissionRate);
          let ticketsArray = response.data.ticketItems.map((ticket) => {
            return {
              name: ticket.name,
              quantity: ticket.quantity,
              price: ticket.ticketTier.price / 100,
              currency: response?.data?.currency,
            };
          });
          let addonsArray = response.data.addonItems.map((addon) => {
            return {
              name: addon.name,
              count: addon.quantity,
              price: addon.addon.price / 100,
            };
          });
          let eventObj = {
            location: response.data.event.location,
            locationLink: response.data.event.locationLink,
            title: response.data.event.name,
            image: response.data.event.banner,
            time: new Date(response.data.event.startsAt).toLocaleTimeString(
              [],
              timeOptions
            ),
            date: response.data.event.startsAt,
            id: response.data.event.pk,
          };
          setTickets(ticketsArray);
          setAddons(addonsArray);
          setEvent(eventObj);
          setStatus({
            text:
              response.data.paymentStatus === "pending" &&
              response.data.paymentType === "kiosk"
                ? "Pending Payment"
                : statusMapper[response.data.paymentStatus],
            type: statusTypeMapper[response.data.paymentStatus],
            original: response.data.paymentStatus,
          });
          setPaymentMethod(response.data.paymentType);
          setBillReference(response.data.billReference);
          setTicketsFile(
            response.data.ticketsFile?.replace("http", "https") || null
          );
          setLoading(false);
          setDiscount(response?.data?.discount / 100 || 0);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404 && !failed.current) {
          failed.current = true;
          fetchReservationOrder();
        } else {
          setLoading(false);
        }
      });
  };

  const getCartTotal = () => {
    let sum = 0;
    let price = 0;
    for (let i = 0; i < tickets.length; i++) {
      sum += tickets[i].quantity;
      price += tickets[i].quantity * tickets[i].price;
    }
    for (let i = 0; i < addons.length; i++) {
      price += addons[i].price * addons[i].count;
    }
    setTicketCount(sum);
    setSubtotal(price);
  };

  const handlePayment = (method, isFull = true) => {
    setPaymentLoading(true);
    api.reservations
      .payReservation(
        reservationId,
        orderId,
        method,
        false,
        isFull ? undefined : customAmount,
        method === "fawry"
          ? "subsbase"
          : (method === "valu" || method === "card") && currency !== "USD"
          ? "paytabs"
          : undefined
      )
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setPaymentData({
            type: paymentMethod,
            url: response.data.cardPaymentUrl,
            reference: response.data.billReference,
            solution: response.data.paymentSolution,
            orderId: response.data.order,
            clientSecret: response?.data?.extraData?.clientSecret,
          });
          setPaymentLoading(false);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 500 && err?.response?.data?.detail) {
          setPaymentError(err?.response?.data?.detail);
        }
        setPaymentLoading(false);
      });
  };

  const cancelOrder = () => {
    if (!cartToken) {
      return;
    }
    if (timeOutRef !== null) {
      clearInterval(timeOutRef.current);
    }
    api.cart.cancelCart(cartToken).then((response) => {
      if (response.status && response.status >= 200 && response.status < 300) {
        setShowCancelConfirmation(false);
        fetchOrder();
        setShowActionBar(false);
        setExpired(false);
        setExpiry(null);
      }
    });
  };

  if (loading) {
    return <PageLoader />;
  }

  if (showTechnicalDifficulties) {
    return (
      <TechnicalDifficulties
        onClose={() => setShowTechnicalDifficulties(false)}
      />
    );
  }

  return (
    <React.Fragment>
      <div
        className={
          "payment-confirmation" +
          (showActionBar ? " payment-confirmation--with-action-bar" : "")
        }
      >
        <PaymentConfirmationEventBanner event={event} />
        <div className="payment-confirmation__details">
          <div className="payment-confirmation__title">
            <div className="payment-confirmation__title-container">
              {status?.type === "success" && (
                <SuccessIcon className="payment-confirmation__success-icon" />
              )}
              {status?.type === "error" && (
                <ErrorIcon className="payment-confirmation__error-icon" />
              )}
              {status?.type === "pending" && (
                <ClockIcon className="payment-confirmation__pending-icon" />
              )}
              <h3
                className={
                  "payment-confirmation__status" +
                  (status.type === "success"
                    ? " payment-confirmation__status-success"
                    : status.type === "error"
                    ? " payment-confirmation__status-error"
                    : " payment-confirmation__status-pending")
                }
              >
                {status?.text || ""}
              </h3>
            </div>
            {status?.original === "paid" && ticketsFile && (
              <a
                href={ticketsFile}
                download
                target="_blank"
                className="btn btn--secondary payment-confirmation__download-btn"
                rel="noreferrer"
              >
                <DownlaodIcon className="payment-confirmation__download-icon" />{" "}
                Download Tickets
              </a>
            )}
          </div>
          {status?.original === "pending" && (
            <p className="payment-confirmation__fail-paragraph">
              Please allow 24-48 hours for a response
            </p>
          )}
          {/* {status?.original === "repayment" && (
            <p className="payment-confirmation__fail-paragraph">
              Please allow 24-48 hours for a response
            </p>
          )}
          {status?.original === "rejected" && (
            <p className="payment-confirmation__fail-paragraph">
              Please allow 24-48 hours for a response
            </p>
          )}
          {status?.original === "rejected_ratio" && (
            <p className="payment-confirmation__fail-paragraph">
              Please allow 24-48 hours for a response
            </p>
          )}
          {status?.original === "paid" && (
            <p className="payment-confirmation__fail-paragraph">
              Please allow 24-48 hours for a response
            </p>
          )}
          {status?.original === "partially_paid" && (
            <p className="payment-confirmation__fail-paragraph">
              Please allow 24-48 hours for a response
            </p>
          )}
          {(status?.original === "cancelled" ||
            status?.original === "canceled") && (
            <p className="payment-confirmation__fail-paragraph">
              Please allow 24-48 hours for a response
            </p>
          )}
          {status?.original === "friend_pay_fail" && (
            <p className="payment-confirmation__fail-paragraph">
              Please allow 24-48 hours for a response
            </p>
          )}
          {status?.original === "balance_due" && (
            <p className="payment-confirmation__fail-paragraph">
              Please allow 24-48 hours for a response
            </p>
          )} */}
          {status?.original === "order_failed" && (
            <p className="payment-confirmation__fail-paragraph">
              Failed to save your card. This the small fee being charged to save
              your card failed. Your order has not been submitted in this case.
              Please start over by clicking{" "}
              <Link
                to={`/booking/${event.id}`}
                className="payment-confirmation__fail-link"
              >
                here
              </Link>
            </p>
          )}
          {paymentMethod === "kiosk" && status?.original === "pending" && (
            <div className="payment-confirmation__kiosk-dialog">
              <p className="payment-confirmation__reference-number">
                {billReference}
              </p>
              <p className="payment-confirmation__reference-title">
                reference code
              </p>
              <div className="payment-confirmation__kiosk-dialog-status">
                <KioskIcon className="payment-confirmation__payment-method-icon" />
                To be paid Through Kiosk
              </div>
              <p className="payment-confirmation__kiosk-description">
                Head to your nearest Kiosk to pay your items using your
                Reference code ({billReference})
              </p>
            </div>
          )}
          {(status?.original === "failed" || status?.original === "pending") &&
            cartToken &&
            user?.pk === customer?.pk && (
              <div className="payment-confirmation__cancel-order-container">
                <div
                  className="payment-confirmation__cancel-order"
                  onClick={() => setShowCancelConfirmation(true)}
                >
                  <CancelIcon className="payment-confirmation__cancel-order-icon" />{" "}
                  Cancel Order
                </div>
              </div>
            )}
          {status?.original !== "order_failed" && (
            <React.Fragment>
              <CheckoutReceipt
                tickets={tickets}
                subtotal={subtotal}
                addons={addons}
                setGrandTotal={setGrandTotal}
                grandTotal={grandTotal}
                currency={currency}
                discount={discount}
                commission={commission}
              />
              <div className="checkout__grand-total">
                <h3 className="checkout__grand-total-title">Order total</h3>
                <div className="checkout__grand-total-value">
                  <p>
                    {formatNumbers(grandTotal, true)} {currency}
                  </p>
                </div>
              </div>
            </React.Fragment>
          )}

          {+remainingBalance > 0 && paidAmount > 0 && (
            <React.Fragment>
              <div className="balance-due success-message">
                <p className="balance-due__title">Already paid</p>
                <p className="balance-due__value">
                  {formatNumbers(paidAmount, true)} {currency}
                </p>
              </div>
              <div className="balance-due">
                <p className="balance-due__title">Balance due</p>
                <p className="balance-due__value">
                  {formatNumbers(remainingBalance, true)} {currency}
                </p>
              </div>
              {showSplitPaymentOption && (
                <span className="balance-due__description">
                  You can pay the amount in split payments on multiple cards.
                </span>
              )}
            </React.Fragment>
          )}
          {(paymentMethod === "card" || paymentMethod === "installment") &&
            (status?.original === "partially_paid" ||
              status?.original === "paid" ||
              status?.original === "failed" ||
              status?.original === "friend_pay_fail") && (
              <div className="payment-confirmation__payment-method">
                <div
                  className={
                    "payment-confirmation__payment-method-status" +
                    (status?.original === "failed" ||
                    status?.original === "friend_pay_fail"
                      ? " payment-confirmation__payment-method-status--error"
                      : " payment-confirmation__payment-method-status--success")
                  }
                >
                  {status?.original === "failed" ? (
                    <React.Fragment>Payment Failed</React.Fragment>
                  ) : status?.original === "friend_pay_fail" ? (
                    <React.Fragment>
                      Please visit the payment link sent to you via email to
                      secure your pass.
                    </React.Fragment>
                  ) : status?.original === "partially_paid" ? (
                    <React.Fragment>Your Payment Was Successful</React.Fragment>
                  ) : (
                    <React.Fragment>Payment Successful</React.Fragment>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
      <PaymentService
        type={paymentData?.solution}
        onClose={() => {
          setPaymentData({});
        }}
        setLoading={setPaymentLoading}
        stripeClientSecret={paymentData?.clientSecret}
        isStripeSetup={false}
        stripeRedirectUrl={`/orders/${paymentData?.orderId}`}
        cardPaymentUrl={paymentData?.url}
        loading={paymentLoading}
      />
      {showActionBar && (
        <CTAPopup
          actionHandler={() => {
            if (expired) {
              history.push(`/booking/${event.id}`);
            } else {
              setShowPaymentSelector(true);
              /*setShowTechnicalDifficulties(true); */
            }
          }}
          expiry={expiry}
          text={expired ? "New Order" : "Pay"}
          expired={expired}
        />
      )}
      {showCancelConfirmation && (
        <ExpiryPopup
          title={expiry !== null ? "Timer" : ""}
          message="By cancelling your order you will lose your fetched passes."
          actionMessage="Are you sure you want to cancel your order? "
          handleBtn1Click={() => setShowCancelConfirmation(false)}
          handleBtn2Click={cancelOrder}
          btn1="No, Dont't cancel my order"
          btn2="Yes, Cancel my order"
          closable={true}
          close={() => setShowCancelConfirmation(false)}
          expiry={expiry}
        />
      )}
      {showPaymentSelector && !expired && (
        <PaymentSelectorPopup
          handleSubmit={(method, isFull) => {
            handlePayment(method, isFull);
            setShowPaymentSelector(false);
            setCustomAmount(undefined);
          }}
          close={() => {
            setShowPaymentSelector(false);
            setCustomAmount(undefined);
          }}
          eventType={event?.type}
          showPaymentOptions={showPaymentOptions}
          showSplitPaymentOption={showSplitPaymentOption}
          customAmount={customAmount}
          setCustomAmount={setCustomAmount}
          remainingBalance={remainingBalance}
          minAmount={min_payment}
          currency={currency}
        />
      )}
      {paymentError && (
        <ExpiryPopup
          message={paymentError}
          variant="error"
          expiry={null}
          closable={true}
          close={() => setPaymentError("")}
        />
      )}
    </React.Fragment>
  );
};

export default PaymentConfirmation;
