import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { formatNumbers } from "./../utils/formatNumbers";
import CheckoutReceipt from "./../components/Receipt/CheckoutReceipt";
import { options as timeOptions } from "../utils/timeFormat";
import { options as dateOptions } from "../utils/dateFormat";
import { ReactComponent as SuccessIcon } from "../assets/images/order-success-checkmark.svg";
import { ReactComponent as ErrorIcon } from "../assets/images/input-error.svg";
import { ReactComponent as ClockIcon } from "../assets/images/clock.svg";
import * as api from "../api";
import qs from "qs";
import ExpiryPopup from "../components/Utils/ExpiryPopup";
import PaymentConfirmationEventBanner from "../components/Event/PaymentConfirmationEventBanner";
import PaymentService from "../components/Payment/PaymentService";

const Friendpay = () => {
  const [event, setEvent] = useState({});
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [status, setStatus] = useState("");
  const [paymentData, setPaymentData] = useState({});
  const [reservationCode, setReservationCode] = useState("");
  const [token, setToken] = useState("");
  const [fetchOrderError, setFetchOrderError] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState();
  const [commission, setCommission] = useState(0);
  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    getCartTotal();
  }, [tickets]);

  useEffect(() => {
    let queryToken = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).token;
    if (params.id && queryToken) {
      setToken(queryToken);
      api.reservations
        .getFriendOrder(params.id, queryToken)
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            setCurrency(response.data.currency);
            setCommission(response?.data?.region?.commissionRate);
            let eventObj = {
              location: response.data.reservation.event.location,
              locationLink: response.data.reservation.event.locationLink,
              title: response.data.reservation.event.name,
              image: response.data.reservation.event.banner,
              time: new Date(
                response.data.reservation.event.startsAt
              ).toLocaleTimeString([], timeOptions),
              date:
                new Date(
                  response.data.reservation.event.startsAt
                ).toLocaleString("default", {
                  month: "long",
                }) +
                " " +
                new Date(
                  response.data.reservation.event.startsAt
                ).toLocaleString("default", {
                  day: "numeric",
                }) +
                ", " +
                new Date(
                  response.data.reservation.event.startsAt
                ).toLocaleString("default", {
                  year: "numeric",
                }),
              id: response.data.reservation.event.pk,
            };
            let ticketObj = {
              quantity: 1,
              price: response.data.items[0].ticketTier.price / 100,
              name:
                response.data.items[0].customer?.firstName +
                " " +
                response.data.items[0].customer?.lastName +
                " (" +
                response.data.items[0].name +
                ")",
            };
            let ticketsArray = [];
            ticketsArray.push(ticketObj);
            setTickets(ticketsArray);
            setEvent(eventObj);
            setStatus(
              response.data?.paymentStatus
                ? response.data?.paymentStatus
                : "pending"
            );
            setReservationCode(response.data.reservationCode);
            setLoading(false);
          } else {
            setFetchOrderError(
              "Something went wrong while fetching your order"
            );
          }
        })
        .catch((err) => {
          if (
            err?.response?.status === 400 &&
            err?.response?.data?.nonFieldErrors
          ) {
            setFetchOrderError(err.response.data.nonFieldErrors);
          } else {
            setFetchOrderError(
              "Something went wrong while fetching your order"
            );
          }
        });
    } else {
      history.push("/");
    }
  }, []);

  const getCartTotal = () => {
    let price = 0;
    for (let i = 0; i < tickets.length; i++) {
      price += tickets[i].quantity * tickets[i].price;
    }
    setSubtotal(price);
  };

  const handleSubmit = () => {
    setPaymentLoading(true);
    setPaymentError("");
    api.reservations
      .payReservationAnonymously(
        reservationCode,
        params.id,
        "card",
        token,
        false
      )
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setPaymentData({
            type: response?.data?.type,
            url: response?.data?.cardPaymentUrl,
            reference: response?.data?.billReference,
            solution: response?.data?.paymentSolution,
            orderId: response?.data?.order,
            clientSecret: response?.data?.extraData?.clientSecret,
          });
        } else {
          setPaymentError("Something went wrong while fetching your order");
          setPaymentLoading(false);
        }
      })
      .catch((err) => {
        if (
          err?.response?.status === 400 &&
          err?.response?.data?.nonFieldErrors
        ) {
          setPaymentError(err.response.data.nonFieldErrors);
        } else {
          setPaymentError("Something went wrong while fetching your order");
        }
        setPaymentLoading(false);
      });
  };

  return (
    <React.Fragment>
      {!loading && (
        <div className="payment-confirmation">
          <PaymentConfirmationEventBanner event={event} />

          <div className="payment-confirmation__details">
            <div className="payment-confirmation__title">
              <div className="payment-confirmation__title-container">
                {status === "paid" && (
                  <SuccessIcon className="payment-confirmation__success-icon" />
                )}
                {status === "failed" && (
                  <ErrorIcon className="payment-confirmation__error-icon" />
                )}
                {status === "pending" && (
                  <ClockIcon className="payment-confirmation__pending-icon" />
                )}
                <h3
                  className={
                    "payment-confirmation__status" +
                    (status === "paid"
                      ? " payment-confirmation__status-success"
                      : status === "failed"
                      ? " payment-confirmation__status-error"
                      : " payment-confirmation__status-pending")
                  }
                >
                  {status === "paid" && "Payment Successful"}
                  {status === "pending" && "Pending Payment"}
                  {status === "failed" && "Payment Failed"}
                </h3>
              </div>
            </div>
            <div className="payment-confirmation__upgrade-dialog">
              <p className="payment-confirmation__upgrade-dialog-title">
                PASS PURCHASE
              </p>
              {tickets.map((ticket, index) => (
                <p
                  className="payment-confirmation__upgrade-dialog-description"
                  key={index}
                >
                  {ticket.name}
                </p>
              ))}
            </div>
            <CheckoutReceipt
              tickets={tickets}
              subtotal={subtotal}
              setGrandTotal={setGrandTotal}
              grandTotal={grandTotal}
              currency={currency}
              commission={commission}
            />
            <div className="checkout__grand-total">
              <h3 className="checkout__grand-total-title">Total</h3>
              <div className="checkout__grand-total-value">
                <p>
                  {formatNumbers(grandTotal)} {currency}
                </p>
              </div>
            </div>
            {status === "paid" ? (
              <></>
            ) : (
              <div className="payment-confirmation__btn-container">
                <button
                  type="button"
                  className="btn btn--primary payment-confirmation__btn"
                  onClick={() => handleSubmit()}
                >
                  Pay
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <PaymentService
        type={paymentData?.solution}
        onClose={() => {
          setPaymentData({});
        }}
        setLoading={setPaymentLoading}
        stripeClientSecret={paymentData?.clientSecret}
        isStripeSetup={false}
        stripeRedirectUrl={`/orders/friend-pay/${paymentData?.orderId}?token=${token}`}
        cardPaymentUrl={paymentData?.cardPaymentUrl}
        loading={paymentLoading}
      />
      {fetchOrderError && (
        <ExpiryPopup
          title={null}
          variant="error"
          message={fetchOrderError}
          actionMessage="Do you wish to try again?"
          handleBtn1Click={() => window.location.reload()}
          handleBtn2Click={() => history.replace("/")}
          btn1="Yes"
          btn2="No"
          closable={false}
        />
      )}
      {paymentError && (
        <ExpiryPopup
          title={null}
          variant="error"
          message={paymentError}
          actionMessage="Do you wish to try again?"
          handleBtn1Click={() => handleSubmit()}
          handleBtn2Click={() => setPaymentError("")}
          btn1="Yes"
          btn2="No"
          closable={true}
          close={() => setPaymentError("")}
        />
      )}
    </React.Fragment>
  );
};

export default Friendpay;
