import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as api from "../api";
import CheckoutReceipt from "../components/Receipt/CheckoutReceipt";
import { ReactComponent as SuccessIcon } from "../assets/images/order-success-checkmark.svg";
import VisaIcon from "../assets/images/visa-icon.svg";
import MasterCardIcon from "../assets/images/mastercard-icon.svg";
import { options as timeOptions } from "../utils/timeFormat";
import { ReactComponent as ErrorIcon } from "../assets/images/input-error.svg";
import { formatNumbers } from "../utils/formatNumbers";
import CTAPopup from "./../components/Event/CTAPopup";
import qs from "qs";
import PaymentConfirmationEventBanner from "./../components/Event/PaymentConfirmationEventBanner";
import PaymentService from "../components/Payment/PaymentService";
import { min_payment } from "../constants";
import { ReactComponent as ClockIcon } from "./../assets/images/clock.svg";
import PaymentSelectorPopup from "../components/Utils/PaymentSelectorPopup";

const PresalesReceipt = ({ location = {} }) => {
  const params = useParams();
  const [event, setEvent] = useState({});
  const [grandTotal, setGrandTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [status, setStatus] = useState("");
  const [tickets, setTickets] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [reservationId, setReservationId] = useState("");
  const [token, setToken] = useState("");
  const [currency, setCurrency] = useState();
  const [paymentData, setPaymentData] = useState();
  const [customAmount, setCustomAmount] = useState();
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [showPaymentSelector, setShowPaymentSelector] = useState(false);
  const [commission, setCommission] = useState(0);

  const showPaymentOptions = true;
  const showSplitPaymentOption = false;

  useEffect(() => {
    let tk = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).token;
    setToken(tk);
    if (params.id) {
      api.orders.getInvitationReceipt(params.id).then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          let ticketsCopy = [];
          let responseItems = response.data.items;
          for (let i = 0; i < responseItems.length; i++) {
            let current = ticketsCopy.findIndex(
              (ticket) => ticket.pk === responseItems[i].ticketTier.pk
            );
            if (current !== -1) {
              ticketsCopy[current].quantity += 1;
            } else {
              ticketsCopy.push({
                name:
                  responseItems[i].ticketTier.name +
                  " " +
                  responseItems[i].ticketTier.nameLine2 +
                  " " +
                  responseItems[i].ticketTier.nameLine3,
                quantity: 1,
                price: responseItems[i].ticketTier.price / 100,
                pk: responseItems[i].ticketTier.pk,
              });
            }
          }
          let eventObj = {
            location: response.data.reservation.event.location,
            locationLink: response.data.reservation.event.locationLink,
            title: response.data.reservation.event.name,
            image: response.data.reservation.event.banner,
            time: new Date(
              response.data.reservation.event.startsAt
            ).toLocaleTimeString([], timeOptions),
            date: response.data.reservation.event.startsAt,
            id: response.data.reservation.event.pk,
          };
          if (
            response?.data?.paidAmount !== response?.data?.amount &&
            response?.data?.paidAmount > 0
          ) {
            //Partially paid, show popup to pay
            setStatus("balance_due");
          } else {
            setStatus(response.data.isPaid ? "paid" : "unpaid");
          }
          setEvent(eventObj);

          setTickets(ticketsCopy);
          setReservationId(response.data.reservation.code);
          setCurrency(response?.data?.currency);
          setRemainingBalance(
            (response?.data?.amount - response?.data?.paidAmount) / 100
          );
          setPaidAmount(response?.data?.paidAmount / 100);
          setCommission(response?.data?.region?.commissionRate);
        }
      });
    }
  }, []);
  useEffect(() => {
    getCartTotal();
  }, [tickets]);

  const getCartTotal = () => {
    let sum = 0;
    let price = 0;
    for (let i = 0; i < tickets.length; i++) {
      sum += tickets[i].quantity;
      price += tickets[i].quantity * tickets[i].price;
    }
    setSubtotal(price);
  };

  const handleRetryPayment = (isFull = true) => {
    setPaymentLoading(true);
    api.reservations
      .payReservationAnonymously(
        reservationId,
        params.id,
        "card",
        token,
        false
        /* isFull ? undefined : customAmount */
      )
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setPaymentData({
            url: response?.data?.cardPaymentUrl,
            paymentSolution: response?.data?.paymentSolution,
            clientSecret: response?.data?.extraData?.clientSecret,
            orderId: response?.data?.order,
          });
          setPaymentLoading(false);
        }
      })
      .catch(() => {
        setPaymentLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div
        className={
          "payment-confirmation" +
          (status !== "paid" ? " payment-confirmation--with-action-bar" : "")
        }
      >
        <PaymentConfirmationEventBanner event={event} />
        <div className="payment-confirmation__details">
          <div className="payment-confirmation__title">
            <div className="payment-confirmation__title-container">
              {status === "paid" && (
                <SuccessIcon className="payment-confirmation__success-icon" />
              )}
              {status === "unpaid" && (
                <ErrorIcon className="payment-confirmation__error-icon" />
              )}
              {status === "balance_due" && (
                <ClockIcon className="payment-confirmation__pending-icon" />
              )}
              <h3
                className={
                  "payment-confirmation__status" +
                  (status === "paid"
                    ? " payment-confirmation__status-success"
                    : status === "unpaid"
                    ? " payment-confirmation__status-error"
                    : " payment-confirmation__status-pending")
                }
              >
                {status === "paid" && "Paid"}
                {status === "unpaid" && "Payment Failed"}
                {status === "balance_due" && "Balance Due"}
              </h3>
            </div>
          </div>
          {status === "paid" && (
            <p className="payment-confirmation__paragraph">
              Your payment has been confirmed! Check your email to receive your{" "}
              {event?.title || ""} Passes.
            </p>
          )}

          <CheckoutReceipt
            tickets={tickets}
            subtotal={subtotal}
            setGrandTotal={setGrandTotal}
            grandTotal={grandTotal}
            currency={currency}
            commission={commission}
          />
          <div className="checkout__grand-total">
            <h3 className="checkout__grand-total-title">Order total</h3>
            <div className="checkout__grand-total-value">
              <p>
                {formatNumbers(grandTotal)} {currency}
              </p>
            </div>
          </div>

          {/* {status?.original === "balance_due" && (
            <React.Fragment>
              <div className="balance-due success-message">
                <p className="balance-due__title">Already paid</p>
                <p className="balance-due__value">
                  {formatNumbers(paidAmount, true)} {currency}
                </p>
              </div>
              <div className="balance-due">
                <p className="balance-due__title">Balance due</p>
                <p className="balance-due__value">
                  {formatNumbers(remainingBalance, true)} {currency}
                </p>
              </div>
              {showSplitPaymentOption && (
                <span className="balance-due__description">
                  You can pay the amount in split payments on multiple cards.
                </span>
              )}
            </React.Fragment>
          )} */}

          {status === "paid" && (
            <div className="payment-confirmation__payment-method">
              <div className="payment-confirmation__payment-method-status">
                <React.Fragment>
                  {status === "paid" && "paid by card"}
                  {status === "unpaid" && "Payment unsuccessful"}
                </React.Fragment>
                <div>
                  <img
                    src={VisaIcon}
                    className="payment-confirmation__payment-method-image"
                    alt="card"
                  />
                  <img
                    src={MasterCardIcon}
                    className="payment-confirmation__payment-method-image"
                    alt="card"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <PaymentService
          type={paymentData?.paymentSolution}
          onClose={() => {
            setPaymentData({});
            paymentData?.paymentSolution === "paymob" &&
              setPaymentLoading(false);
          }}
          setLoading={setPaymentLoading}
          stripeClientSecret={paymentData?.clientSecret}
          isStripeSetup={false}
          stripeRedirectUrl={`/invitations/receipt/${paymentData?.orderId}`}
          paymobUrl={paymentData?.url}
          loading={paymentLoading}
        />
      </div>
      {status !== "paid" && (
        <CTAPopup
          actionHandler={() => {
            setShowPaymentSelector(true);
            handleRetryPayment();
          }}
          text="Retry Payment"
        />
      )}
      {showPaymentSelector && (
        <PaymentSelectorPopup
          handleSubmit={(method, isFull) => {
            handleRetryPayment(isFull);
            setShowPaymentSelector(false);
            setCustomAmount(undefined);
          }}
          close={() => {
            setShowPaymentSelector(false);
            setCustomAmount(undefined);
          }}
          showPaymentOptions={showPaymentOptions}
          showSplitPaymentOption={showSplitPaymentOption}
          customAmount={customAmount}
          setCustomAmount={setCustomAmount}
          remainingBalance={remainingBalance}
          minAmount={min_payment}
          currency={currency}
        />
      )}
    </React.Fragment>
  );
};

export default PresalesReceipt;
