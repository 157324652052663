import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const ticketTiers = async (id, token = "", region = "") => {
  const queryParam = token
    ? `?token=${token}` + (region ? `&region=${region}` : "")
    : region
    ? `?region=${region}`
    : "";
  const response = await axios.get(
    routes.ticketTiers(id) + queryParam,
    AXIOS_DEFAULT_CONFIG
  );
  return response;
};
export default ticketTiers;
