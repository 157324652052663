import React from "react";
import EventDetails from "./../../pages/EventDetails";
import Overlay from "./../Utils/Overlay";
import { ReactComponent as CloseIcon } from "../../assets/images/cross.svg";

const EventPopup = ({ close = () => {}, eventId = null }) => {
  return (
    <React.Fragment>
      <div className="popup event-info-popup">
        <CloseIcon className="popup__close" onClick={close} />
        <div className="popup__body">
          <EventDetails eventId={eventId} />
        </div>
      </div>
      <Overlay handleOnClick={close} />
    </React.Fragment>
  );
};

export default EventPopup;
