import React, { useState, useEffect } from "react";
import * as api from "../api";
import CheckoutDetails from "../components/Event/CheckoutDetails";
import qs from "qs";
import { options as dateOptions } from "../utils/dateFormat";
import { options as timeOptions } from "../utils/timeFormat";
import CTAPopup from "./../components/Event/CTAPopup";
import GuestsForm from "../components/Forms/GuestsForm";
import TicketSelector from "./../components/Utils/TicketSelector";
import PaymentConfirmationEventBanner from "./../components/Event/PaymentConfirmationEventBanner";
import { ReactComponent as SuccessIcon } from "../assets/images/order-success-checkmark.svg";
import CheckoutReceipt from "./../components/Receipt/CheckoutReceipt";
import { useHistory } from "react-router-dom";
import PaymentService from "../components/Payment/PaymentService";

const Presales = ({ location = {} }) => {
  const [guestsMapper, setGuestsMapper] = useState([]);
  const [expanded, setExpanded] = useState(0);
  const [showReceipt, setShowReceipt] = useState(false);
  const [invitationTickets, setInvitationTickets] = useState([]);
  const [invitationLoading, setInvitationLoading] = useState(true);
  const [token, setToken] = useState("");
  const [userToken, setUserToken] = useState("");
  const [event, setEvent] = useState({});
  const [selectedTickets, setSelectedTickets] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [tokenError, setTokenError] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [paymentData, setPaymentData] = useState("");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [userError, setUserError] = useState(false);
  const [isFoc, setIsFoc] = useState(null);
  const [requiresInfo, setRequiresInfo] = useState(null);
  const [focSuccess, setFocSuccess] = useState(false);
  const [resHead, setResHead] = useState({});
  const [currency, setCurrency] = useState("");
  const [commission, setCommission] = useState(0);

  const history = useHistory();

  const guestTemplate = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: null,
    ticket: null,
    errors: {},
    registered: false,
  };

  useEffect(() => {
    let token = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).token;
    let user_token = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).user_token;
    if (token && user_token) {
      setToken(token);
      setUserToken(user_token);
      fetchInvitation(token);
    } else {
      setTokenError("This link is either invalid or has been used before");
    }
  }, []);

  useEffect(() => {
    let price = 0;
    for (const ticket in selectedTickets) {
      price += selectedTickets[ticket].quantity * selectedTickets[ticket].price;
    }
    setSubtotal(price);
  }, [selectedTickets]);

  const fetchInvitation = (id) => {
    api.orders
      .getInvitationOrder(id)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setCurrency(response?.data?.tiers?.[0]?.tier?.region?.currency);
          setCommission(response?.data?.tiers?.[0]?.tier?.region?.commissionRate)
          let eventObj = {
            title: response.data.event.name,
            description: response.data.event.description,
            image: response.data.event.thumbnail,
            banner: response.data.event.banner,
            date: new Date(response.data.event.startsAt).toLocaleDateString(
              [],
              dateOptions
            ),
            artists: response.data.event.artists,
            location: response.data.event.location,
            locationLink: response.data.event.locationLink,
            startsAt: new Date(response.data.event.startsAt).toLocaleTimeString(
              [],
              timeOptions
            ),
            time: new Date(response.data.event.startsAt).toLocaleTimeString(
              [],
              timeOptions
            ),
            date: response.data.event.startsAt,
            id: response.data.event.pk,
            layoutImage: response.data.event.siteMap,
            type: response.data.event.reservationProcessType,
          };
          let parsedTickets = response.data.tiers.map((item) => {
            return {
              ...item,
              tier: {
                ...item.tier,
                price: item.tier.price / 100,
              },
            };
          });
          setIsFoc(response.data.isFoc);
          setRequiresInfo(response.data.requiresInfo);
          setInvitationTickets(parsedTickets);
          if (response.data.reservationHeadLocked) {
            let genderObject = {};
            if (response.data.customer.gender === "f") {
              genderObject.label = "Female";
              genderObject.value = "f";
            } else if (response.data.customer.gender === "m") {
              genderObject.label = "Male";
              genderObject.value = "m";
            }
            setResHead({
              firstName: response.data.customer.firstName,
              lastName: response.data.customer.lastName,
              email: response.data.customer.email,
              phone: response.data.customer.mobileNumber,
              gender: genderObject,
              pk: response.data.customer.pk,
              ticket: null,
              errors: {},
              registered: true,
            });
          }
          init(eventObj);
        }
      })
      .catch(() => {
        setTokenError("This link is either invalid or has been used before");
      });
  };

  const init = (parsedEvent) => {
    setEvent(parsedEvent);
    setInvitationLoading(false);
  };

  const areFormsValid = () => {
    let valid = true;
    let guests = [...guestsMapper];
    if (requiresInfo) {
      for (let i = 0; i < guests.length; i++) {
        let guest = { ...guests[i] };
        let errs = { ...guest.errors };
        if (!guest.email) {
          errs.email = "Email is required";
        }
        if (!guest.firstName) {
          errs.firstName = "First name is required";
        }
        if (!guest.lastName) {
          errs.lastName = "Last name is required";
        }
        if (!guest.ticket) {
          errs.ticket = "Select a ticket";
        }
        if (!guest.gender) {
          errs.gender = "Select gender";
        }
        if (!guest.phone) {
          errs.phone = "Phone is required";
        }
        for (let j = i + 1; j < guests.length; j++) {
          let otherGuest = { ...guests[j] };
          let otherGuestErrors = { ...otherGuest.errors };
          if (guest.email && otherGuest.email === guest.email) {
            otherGuestErrors.email = "This email has already been entered";
          }
          otherGuest.errors = otherGuestErrors;
          guests[j] = otherGuest;
        }
        guest.errors = errs;
        if (Object.keys(guest.errors).length !== 0) {
          valid = false;
        }
        guests[i] = guest;
      }
    }
    if (valid === false && requiresInfo) {
      expandGuestWithError();
    }
    if (requiresInfo) {
      setUserError(valid === true ? false : true);
      setGuestsMapper(guests);
    }

    return valid;
  };
  const registerUsers = async () => {
    let guests = [...guestsMapper];
    for (let i = 0; i < guests.length; i++) {
      let current = { ...guests[i] };
      if (!current.registered) {
        try {
          let { data } = await api.auth.anonymousRegister(
            current.email,
            current.firstName,
            current.lastName,
            current.gender.value,
            current.phone,
            userToken
          );
          current.pk = data.pk;
          guests[i] = current;
          setGuestsMapper(guests);
        } catch (err) {
          if (
            err.response &&
            err?.response?.status === 400 &&
            err?.response?.data?.nonFieldErrors
          ) {
            setSubmitError(err.response.data.nonFieldErrors[0]);
          } else if (err.response && err?.response?.status === 403) {
            setTokenError(
              "This link is either invalid or has been used before"
            );
          } else {
            setSubmitError(
              "Something went wrong while registering " + current.email
            );
          }
          setSubmitLoading(false);
          return;
        }
      }
    }
    return guests;
  };

  const createOrder = (guests = null) => {
    let body = {};
    const infoPortalMap = {
      true: "order",
      false: "purchase",
    };
    if (requiresInfo) {
      let items = [];
      for (let i = 0; i < guests.length; i++) {
        items.push({
          customer: guests[i].pk,
          ticketTier: guests[i].ticket.ticketTier,
        });
      }
      body = {
        items,
        reservationHead:
          Object.keys(resHead).length !== 0 ? resHead.pk : guests[0].pk,
      };
    } else {
      let invitationTiers = [];
      for (const tier in selectedTickets) {
        invitationTiers.push({
          id: tier,
          purchased: selectedTickets[tier].quantity,
        });
      }
      body = { type: "card", invitationTiers };
    }
    api.orders
      .sendInvitationOrder(token, body, infoPortalMap[requiresInfo])
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          if (requiresInfo && !isFoc) {
            history.replace("/orders/" + response?.data?.order);
          }
          setSubmitLoading(false);
          if (!isFoc) {
            setPaymentData({
              url: response?.data?.cardPaymentUrl,
              paymentSolution: response?.data?.paymentSolution,
              clientSecret: response?.data?.extraData?.clientSecret,
              orderId: response?.data?.order,
            });
            setPaymentLoading(true);
          } else {
            setFocSuccess(true);
          }
        } else {
          setSubmitError("Something went wrong");
          setSubmitLoading(false);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          setTokenError("This link is either invalid or has been used before");
        } else if (
          err.response &&
          err?.response?.status === 400 &&
          err?.response?.data?.nonFieldErrors
        ) {
          setSubmitError(err.response.data.nonFieldErrors[0]);
        } else {
          setSubmitError("Something went wrong");
        }
        setSubmitLoading(false);
      });
  };

  const handleSubmit = async () => {
    setSubmitError("");
    if (areFormsValid()) {
      setSubmitLoading(true);
      let guests = null;
      if (requiresInfo) {
        guests = await registerUsers();
      }
      createOrder(guests ? guests : null);
    }
  };

  const expandGuestWithError = () => {
    for (let i = 0; i < guestsMapper.length; i++) {
      let errs = { ...guestsMapper[i].errors };
      if (
        Object.keys(errs).length !== 0 ||
        !guestsMapper[i].email ||
        !guestsMapper[i].ticket ||
        !guestsMapper[i].firstName ||
        !guestsMapper[i].lastName ||
        !guestsMapper[i].gender ||
        !guestsMapper[i].phone
      ) {
        setExpanded(i);
        break;
      }
    }
  };

  return (
    <React.Fragment>
      <div
        className={
          "presales-page" + (focSuccess ? " payment-confirmation" : "")
        }
      >
        {tokenError && <h1 className="presales-page__error">{tokenError}</h1>}
        {focSuccess && (
          <React.Fragment>
            <PaymentConfirmationEventBanner
              event={{ ...event, image: event.banner }}
            />
            <div className="payment-confirmation__details">
              <div className="payment-confirmation__title">
                <div className="payment-confirmation__title-container">
                  <SuccessIcon className="payment-confirmation__success-icon" />
                  <h3 className="payment-confirmation__status payment-confirmation__status-success">
                    Passes Successfully Submitted
                  </h3>
                </div>
              </div>

              <CheckoutReceipt
                tickets={Object.keys(selectedTickets).map((key) => {
                  return { ...selectedTickets[key] };
                })}
                subtotal={subtotal}
                addons={[]}
                setGrandTotal={setGrandTotal}
                grandTotal={grandTotal}
                isFoc={isFoc}
                currency={currency}
                commission={commission}
              />
              <div className="checkout__grand-total">
                <h3 className="checkout__grand-total-title">Order total</h3>
                <div className="checkout__grand-total-value">
                  <p>FREE</p>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {!invitationLoading && !tokenError && !focSuccess && (
          <React.Fragment>
            {requiresInfo === true ? (
              <GuestsForm
                setExpanded={setExpanded}
                setGuestsMapper={setGuestsMapper}
                setSelectedTickets={setSelectedTickets}
                setUserLoading={setUserLoading}
                selectedTickets={selectedTickets}
                invitationTickets={invitationTickets}
                guestsMapper={guestsMapper}
                guestTemplate={guestTemplate}
                expanded={expanded}
                isFoc={isFoc}
                resHead={resHead}
              />
            ) : requiresInfo === false ? (
              <TicketSelector
                tickets={invitationTickets}
                selectedTickets={selectedTickets}
                setSelectedTickets={setSelectedTickets}
                isFoc={isFoc}
              />
            ) : (
              ""
            )}
            <CheckoutDetails
              event={event}
              tickets={selectedTickets}
              subtotal={subtotal}
              grandTotal={grandTotal}
              setGrandTotal={setGrandTotal}
              showReceipt={showReceipt}
              setShowReceipt={setShowReceipt}
              isFoc={isFoc}
              currency={currency}
              commission={commission}
            />
          </React.Fragment>
        )}
      </div>
      <PaymentService
        type={paymentData?.paymentSolution}
        onClose={() => {
          setPaymentData({});
        }}
        setLoading={setPaymentLoading}
        stripeClientSecret={paymentData?.clientSecret}
        isStripeSetup={false}
        stripeRedirectUrl={`/invitations/receipt/${paymentData?.orderId}`}
        cardPaymentUrl={paymentData?.url}
        loading={paymentLoading}
        /* onPaymentFail={() => {
          history.replace(`/invitations/receipt/${paymentData?.orderId}`);
        }} */
      />
      {!submitLoading && !submitError && !focSuccess && (
        <CTAPopup
          actionHandler={() => {
            setUserError(false);
            handleSubmit();
          }}
          title=""
          variation={userError ? "error-single" : "single"}
          text={isFoc ? "Submit" : "Book"}
          disabled={userLoading || Object.keys(selectedTickets).length === 0}
          warning={userError ? "OOPS!" : ""}
          displayMessage={userError ? "Please fill all required fields" : ""}
        />
      )}
      {submitLoading && (
        <CTAPopup
          actionHandler={() => {}}
          variation="no-action"
          displayMessage="Please wait while we process your order..."
          showOverlay={true}
          loading={submitLoading}
        />
      )}
      {!submitLoading && submitError && (
        <CTAPopup
          actionHandler={() => setSubmitError("")}
          warning="OOPS!"
          displayMessage={submitError}
          variation="error-single"
          text="Close"
          cancel={() => setSubmitError("")}
          showOverlay={true}
        />
      )}
    </React.Fragment>
  );
};

export default Presales;
