import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const addTicketsToCart = async (items, event, token) => {
  let body = { items, event };
  if (token) {
    body.token = token;
  }
  const response = await axios.post(
    routes.addTicketsToCart,
    body,
    AXIOS_DEFAULT_CONFIG
  );
  return response;
};
export default addTicketsToCart;
