import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const getFriendOrder = async (id, token) => {
  const response = await axios.get(
    routes.getFriendOrder(id, token),
    AXIOS_DEFAULT_CONFIG
  );
  return response;
};
export default getFriendOrder;
