import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const createUser = async (email, firstName, lastName, gender, mobileNumber) => {
    const response = await axios.post(routes.createUser, {
        email, firstName, lastName, gender, mobileNumber
    }, AXIOS_DEFAULT_CONFIG);
    return response;
}
export default createUser;