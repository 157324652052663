import React, { useState } from "react";
import { ReactComponent as PinOutlinedRed } from "../../assets/images/pin-outlined.svg";
import { ReactComponent as ShareOutlinedRed } from "../../assets/images/share-outlined-red.svg";
import ShareList from "./ShareList";

const ShareAndDirections = ({
  locationLink = null,
  url = window.location.href,
  image = null,
}) => {
  const [showShareList, setShowShareList] = useState(false);

  const handleShareClick = () => {
    if (navigator.share) {
      let shareData = {
        title: "SANDBOX 2022",
        text: "Get your passes now!",
        url: url,
      };
      navigator.share(shareData);
    } else {
      setShowShareList(!showShareList);
      setShowShareList(!showShareList);
    }
  };

  return (
    <React.Fragment>
      <div className="labeled-btn-container">
        <a href={locationLink} target="_blank" className="labeled-btn">
          <PinOutlinedRed className="labeled-btn__icon labeled-btn__icon--directions" />{" "}
          <span className="labeled-btn__text">Get Directions</span>
        </a>
      </div>
      <div className="labeled-btn-container">
        <div className="labeled-btn">
          <ShareOutlinedRed
            className="labeled-btn__icon labeled-btn__icon--share"
            onClick={() => handleShareClick()}
          />{" "}
          <span
            className="labeled-btn__text"
            onClick={() => handleShareClick()}
          >
            Share Event
          </span>
          {showShareList && (
            <ShareList close={() => setShowShareList(false)} url={url} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShareAndDirections;
