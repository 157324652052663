import React, { useState } from "react";
import { ReactComponent as PlusBlack } from "../../assets/images/plus-black.svg";
import { ReactComponent as MinusBlack } from "../../assets/images/minus-black.svg";
import { ReactComponent as DropdownIcon } from "../../assets/images/dropdown.svg";
import { ReactComponent as ClearIcon } from "../../assets/images/clear-icon.svg";

const TicketSelector = ({
  tickets = [],
  selectedTickets = {},
  setSelectedTickets = () => {},
  isFoc = null,
}) => {
  const [expandedTickets, setExpandedTickets] = useState({});

  const handleTicketClick = (id, type, ticket) => {
    let newTickets = { ...selectedTickets };
    if (type === "subtract" && newTickets[id]) {
      if (newTickets[id].quantity - 1 <= 0) {
        delete newTickets[id];
      } else {
        newTickets[id].quantity = newTickets[id].quantity - 1;
      }
    } else if (type === "add" && newTickets[id]) {
      newTickets[id].quantity = newTickets[id].quantity + 1;
    } else if (type === "add" && !newTickets[id]) {
      newTickets[id] = {
        name:
          ticket?.tier?.name +
          ticket?.tier?.nameLine2 +
          ticket?.tier?.nameLine3,
        price: ticket?.tier?.price,
        quantity: 1,
        limit: ticket.limit,
      };
    }
    setSelectedTickets(newTickets);
  };

  return (
    <div className="presales-page__ticket-selector">
      <h3 className="event-page__title event-page__title--ticket-selector">
        Select Your Passes
      </h3>
      <div className="event-page__ticket-subheader">
        <p
          className="event-page__ticket-clear"
          onClick={() => setSelectedTickets({})}
        >
          {Object.keys(selectedTickets).length !== 0 && (
            <React.Fragment>
              <ClearIcon className="event-page__ticket-clear-icon" /> clear
              selection
            </React.Fragment>
          )}
        </p>
      </div>
      {tickets.map((ticket, index) => (
        <div
          className={
            "event-page__ticket-area" +
            (selectedTickets[ticket.id]
              ? " event-page__ticket-area--selected"
              : " event-page__ticket-area--not-selected")
          }
          key={index}
        >
          <div
            className="event-page__ticket-row"
            onClick={() => {
              if (expandedTickets[ticket.id]) {
                let old = { ...expandedTickets };
                delete old[ticket.id];
                setExpandedTickets(old);
              } else {
                setExpandedTickets({
                  ...expandedTickets,
                  [ticket.id]: true,
                });
              }
            }}
            style={{ cursor: ticket?.tier?.description ? "cursor" : "default" }}
          >
            <div className="event-page__ticket-name-wrapper">
              <p className="event-page__ticket-name">
                {ticket?.tier?.name +
                  ticket?.tier?.nameLine2 +
                  ticket?.tier?.nameLine3}
              </p>
              <p className="event-page__ticket-price">
                {isFoc === true ? "Free" : ticket?.tier?.price + ` ${ticket?.tier?.region?.currency}`}
              </p>
            </div>
            <React.Fragment>
              <div className="event-page__ticket-counter">
                <React.Fragment>
                  <button
                    className="event-page__ticket-counter-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTicketClick(ticket.id, "subtract", ticket);
                    }}
                  >
                    <MinusBlack className="event-page__ticket-counter-icon" />
                  </button>
                  <p className="event-page__ticket-count">
                    {selectedTickets[ticket.id]?.quantity || 0}
                  </p>
                  <button
                    className="event-page__ticket-counter-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        !selectedTickets[ticket.id] ||
                        selectedTickets[ticket.id]?.quantity <
                          selectedTickets[ticket.id]?.limit
                      ) {
                        handleTicketClick(ticket.id, "add", ticket);
                      }
                    }}
                  >
                    {(selectedTickets[ticket.id]?.quantity <
                      selectedTickets[ticket.id]?.limit ||
                      !selectedTickets[ticket.id]) && (
                      <PlusBlack className="event-page__ticket-counter-icon" />
                    )}
                  </button>
                </React.Fragment>
              </div>
            </React.Fragment>
            <div className="event-page__ticket-dropdown-icon-wrapper">
              {ticket?.tier?.description && (
                <DropdownIcon
                  className={
                    "event-page__ticket-dropdown-icon" +
                    (expandedTickets[ticket.id]
                      ? " event-page__ticket-dropdown-icon--expanded"
                      : "")
                  }
                />
              )}
            </div>
          </div>
          {ticket?.tier?.description && (
            <div
              className={
                "event-page__ticket-description" +
                (expandedTickets[ticket.id]
                  ? " event-page__ticket-description--expanded"
                  : "")
              }
            >
              {ticket.tier.description}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TicketSelector;
