import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as FacebookIcon } from "../../assets/images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/instagram-icon.svg";
import Logo from "../../assets/images/logo.png";
import Terms from "../../assets/legal/terms_of_use.pdf";
import { redirect_to, hardcoded_event, rights, homepage_link} from "./../../constants";

const Navbar = ({
  isLoggedIn = null,
  handleRegistrationPopup = () => {},
  user = {},
  handleLogout = () => {},
  expiry = null,
}) => {
  const [checkbox, setCheckBox] = useState(false);

  return (
    <React.Fragment>
      <nav className="navbar">
        <a href={homepage_link} className="navbar__logo-container">
          <img src={Logo} alt="" className="navbar__logo" />
          {/* <div className="navbar__logo navbar__logo--placeholder">LOGO</div> */}
        </a>
        {isLoggedIn ? (
          <>
            <Link
              to={Terms}
              target="_blank"
              className="navbar__link navbar__link--logged-in"
            >
              TERMS & POLICY
            </Link>
            <p className="navbar__welcome-message">
              Hey there,{" "}
              <span className="navbar__welcome-name">{user.firstName}</span>
            </p>
          </>
        ) : (
          <div className="navbar__links">
            {/* <Link to="/" className="navbar__link">
              EVENTS
            </Link> */}
            <Link to={Terms} target="_blank" className="navbar__link">
              TERMS & POLICY
            </Link>
            <Link to="/help" className="navbar__link">
              FAQs
            </Link>
            <button
              className="btn btn--secondary navbar__registration-btn"
              onClick={() => {
                handleRegistrationPopup("login", true);
                setCheckBox(false);
              }}
            >
              login | register
            </button>
          </div>
        )}
        {isLoggedIn && (
          <div className="sandwich">
            <input
              type="checkbox"
              className="sandwich__checkbox"
              id="sandwich-toggle"
              checked={checkbox}
              onChange={(e) => setCheckBox(e.target.checked)}
            />
            <label htmlFor="sandwich-toggle" className="sandwich__button">
              <div className="sandwich__icon sandwich__icon--1"></div>
              <div className="sandwich__icon sandwich__icon--2"></div>
            </label>
            <div className="sandwich__background"></div>
            <div className="sandwich__nav">
              <div className="sandwich__nav-body">
                <ul className="sandwich__nav-list">
                  {isLoggedIn && (
                    <React.Fragment>
                      <li
                        className="sandwich__nav-item"
                        onClick={() => setCheckBox(false)}
                      >
                        <Link to={`/profile`} className="sandwich__nav-link">
                          Profile
                        </Link>
                      </li>
                      {hardcoded_event !== null ? (
                        <li
                          className="sandwich__nav-item"
                          onClick={() => setCheckBox(false)}
                        >
                          <Link
                            to={`/booking/${hardcoded_event}`}
                            className="sandwich__nav-link"
                          >
                            Passes
                          </Link>
                        </li>
                      ) : (
                        <li
                          className="sandwich__nav-item"
                          onClick={() => setCheckBox(false)}
                        >
                          <Link to="/" className="sandwich__nav-link">
                            Events
                          </Link>
                        </li>
                      )}
                      <li
                        className="sandwich__nav-item"
                        onClick={() => setCheckBox(false)}
                      >
                        <Link to="/orders" className="sandwich__nav-link">
                          My Orders
                        </Link>
                      </li>
                    </React.Fragment>
                  )}
                  <li
                    className="sandwich__nav-item"
                    onClick={() => setCheckBox(false)}
                  >
                    <Link to="/help" className="sandwich__nav-link">
                      Help {"&"} FAQs
                    </Link>
                  </li>
                </ul>
                {isLoggedIn && (
                  <button
                    onClick={() => {
                      handleLogout();
                      setCheckBox(false);
                    }}
                    className="navbar__logout-btn"
                  >
                    Sign Out
                  </button>
                )}
                <div className="sandwich__nav-socials">
                  <p className="sandwich__nav-socials-paragraph">
                    Follow us on
                  </p>
                  <a
                    href="https://www.facebook.com/nacelle1"
                    target="_blank"
                    className="sandwich__nav-socials-link"
                  >
                    <FacebookIcon className="sandwich__nav-socials-icon" />
                  </a>
                  <a
                    href="https://www.instagram.com/nacelle_"
                    target="_blank"
                    className="sandwich__nav-socials-link"
                  >
                    <InstagramIcon className="sandwich__nav-socials-icon" />
                  </a>
                </div>
                <p className="sandwich__terms">
                  By using our platform you agree to our{" "}
                  <Link
                    to={Terms}
                    className="sandwich__terms sandwich__terms--link"
                    target="_blank"
                  >
                    Terms of Use
                  </Link>{" "}
                  and{" "}
                  <Link
                    to={Terms}
                    target="_blank"
                    className="sandwich__terms sandwich__terms--link"
                  >
                    Privacy Policy
                  </Link>
                </p>
                {rights && <p className="sandwich__rights">{rights}</p>}
              </div>
            </div>
          </div>
        )}
      </nav>
    </React.Fragment>
  );
};

export default Navbar;
