import React, { useEffect, useState } from "react";
import { ReactComponent as User } from "../assets/images/circle-user-solid.svg";
import { ReactComponent as User2 } from "../assets/images/user.svg";
import { ReactComponent as Password } from "../assets/images/lock-solid.svg";
import ChangePassword from "./../components/Forms/ChangePassword";
import ChangeInfo from "./../components/Forms/ChangeInfo";

const Profile = ({
  user = {},
  setShowLogin = () => {},
  userLoading = false,
  setUser = () => {},
  handleLogin = () => {},
}) => {
  const tabs = [
    { label: "Profile", icon: <User2 className="profile__tab-item-icon" /> },
    {
      label: "Password",
      icon: <Password className="profile__tab-item-icon" />,
    },
  ];

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (Object.keys(user).length !== 0 && !userLoading) {
      setShowLogin(false);
    } else if (Object.keys(user).length === 0 && !userLoading) {
      setShowLogin(true);
    }
  }, [user, userLoading]);
  return (
    <React.Fragment>
      {Object.keys(user).length !== 0 && (
        <div className="profile">
          <div className="profile__side-bar">
            <div className="profile__picture-container">
              <User className="profile__picture" />
            </div>
            <h3 className="profile__user-name">
              {user.firstName + " " + user.lastName}
            </h3>
            <div className="profile__tabs">
              {tabs.map((item, index) => (
                <div
                  className={
                    "profile__tab-item" +
                    (index === activeTab ? " profile__tab-item--active" : "")
                  }
                  key={index}
                  onClick={() => setActiveTab(index)}
                >
                  <div className="profile__tab-item-icon-container">
                    {item.icon}
                  </div>
                  <div className="profile__tab-item-label">{item.label}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="profile__body">
            {activeTab === 0 && <ChangeInfo user={user} setUser={setUser} />}
            {activeTab === 1 && (
              <ChangePassword user={user} handleLogin={handleLogin} />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Profile;
