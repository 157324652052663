import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const login = async (email, password) => {
    const response = await axios.post(routes.login, {
        email, password
    }, AXIOS_DEFAULT_CONFIG);
    return response;
}
export default login;