import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const getReservations = async (
) => {
    const response = await axios.get(
        routes.getReservations, AXIOS_DEFAULT_CONFIG
    );
    return response;
};
export default getReservations;