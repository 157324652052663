export const customStyles = (state = "default") => {


  if (state === "default") {
    return {
      container: (provided) => ({
        ...provided,
        height: "100%"
      }),
      control: (provided) => ({
        ...provided,
        borderColor: "#000000",
        "&:hover": { borderColor: "#000000" },
        outline: "none",
        boxShadow: "none",
        borderRadius: "0",
        height: "100%",
        minHeight: "unset",
      }),
      dropdownIndicator: (provided) => ({ ...provided, padding: "0 8px" }),
    }
  }
  else if (state === "error") {
    return {
      container: (provided) => ({
        ...provided,
        height: "100%"
      }),
      control: (provided) => ({
        ...provided,
        borderColor: "#f40000",
        "&:hover": { borderColor: "#f40000" },
        outline: "none",
        boxShadow: "none",
        borderRadius: "0",
        height: "100%",
        minHeight: "unset",
      }),
      dropdownIndicator: (provided) => ({ ...provided, padding: "0 8px" }),
    }
  }
};
