import React, { useState, useEffect } from "react";
import { ReactComponent as RedLock } from "../../assets/images/lock-red.svg";
import { ReactComponent as Lock } from "../../assets/images/lock.svg";
import { ReactComponent as DropdownIcon } from "../../assets/images/dropdown.svg";
import { ReactComponent as DropdownIconRed } from "../../assets/images/dropdown-red.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/users.svg";
import { ReactComponent as EditAddonIcon } from "../../assets/images/edit-addons.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/cross.svg";
import CheckoutReceipt from "../Receipt/CheckoutReceipt";
import EventSummary from "./EventSummary";
import { formatNumbers } from "../../utils/formatNumbers";

const CheckoutDetails = ({
  showReceipt = "",
  setShowReceipt = () => {},
  event = {},
  tickets = {},
  subtotal = 0,
  selectedAddons = {},
  setGrandTotal = () => {},
  grandTotal = 0,
  showEditAddons = null,
  handleStepClick = () => {},
  cancelOrder = () => {},
  editGuests = false,
  isHidden = false,
  isFoc = null,
  currency = "",
  commission = 0
}) => {
  const [touchStart, setTouchStart] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    handleReceiptHeight();
    window.addEventListener("resize", handleReceiptHeight);
    return () => {
      window.removeEventListener("resize", handleReceiptHeight);
    };
  }, [showReceipt]);

  const handleReceiptHeight = () => {
    if (window.innerWidth > 1200) {
      setShowReceipt(true);
    }
  };

  useEffect(() => {
    setCount(() => {
      let total = 0;
      for (const tier in tickets) {
        total += tickets[tier].quantity;
      }
      return total;
    });
  }, [tickets]);
  /* 
  const handleCollapseBarDrag = (e, type) => {
    if (type === "start") {
      setTouchStart(e.changedTouches[0].clientY);
    } else {
      let end = e.changedTouches[0].clientY;
      if (touchStart !== null) {
        if (end + 10 < touchStart) {
          setShowReceipt(true);
        } else if (end - 10 > touchStart) {
          setShowReceipt(false);
        }
        setTouchStart(null);
      }
    }
  }; */

  /*   const getCollapsableReceiptHeight = (state) => {
    let collapsableReceipt =
      document.getElementsByClassName("checkout__details")[0];
    if (state === "collapsed") {
      let header = document.getElementsByClassName("event-summary")[0];
      let grandTotal = document.getElementsByClassName(
        "checkout__grand-total"
      )[0];
      let editAddons = document.getElementsByClassName(
        "checkout__edit-addons-container"
      )[0];
     
      let collapsableStyle =
        collapsableReceipt.currentStyle ||
        window.getComputedStyle(collapsableReceipt);
      let headerStyle = header.currentStyle || window.getComputedStyle(header);
      let grandTotalStyle =
        grandTotal.currentStyle || window.getComputedStyle(grandTotal);
      let editAddonsStyle = null;
      if (editAddons) {
        editAddonsStyle =
          editAddons.currentStyle || window.getComputedStyle(editAddons);
      }
      let height =
      header.offsetHeight +
      parseFloat(headerStyle.marginTop) +
      parseFloat(headerStyle.marginBottom) +
      grandTotal.offsetHeight +
      parseFloat(grandTotalStyle.marginTop) +
      parseFloat(collapsableStyle.paddingTop) +
      parseFloat(collapsableStyle.paddingBottom);
      if (editAddons) {
        height +=
        parseFloat(editAddonsStyle.marginTop) + editAddons.offsetHeight;
      }
      collapsableReceipt.style.height = height + "px";
    } else {
      collapsableReceipt.style.height = "50%";
    }
  }; */
  
  return (
    <div
      className={
        "checkout__details" +
        (!showReceipt ? " checkout__details--collapsed" : "") +
        (isHidden ? " checkout__details--hidden" : "")
      }
      onClick={(e) => e.stopPropagation()}
    >
      {/* <div
        className="checkout__mobile-collapse-bar"
        onTouchStart={(e) => handleCollapseBarDrag(e, "start")}
        onTouchEnd={(e) => handleCollapseBarDrag(e, "end")}
      ></div> */}
      <div className="checkout__toggle">
        <DropdownIcon
          className={
            "checkout__toggle-icon" +
            (!showReceipt ? " checkout__toggle-icon--collapsed" : "")
          }
          onClick={() => setShowReceipt(!showReceipt)}
        />
      </div>
      <EventSummary event={event} />
      <div className="checkout__grand-total">
        <h3 className="checkout__grand-total-title">Order total</h3>
        <div className="checkout__grand-total-value">
          <h3>
            {isFoc === true ? "Free" : formatNumbers(grandTotal) + ` ${currency}`}
          </h3>
        </div>
      </div>
      <div className="checkout__order-actions">
        {editGuests && (
          <div
            className="checkout__edit-guests"
            onClick={() => handleStepClick("guests")}
          >
            <UsersIcon className="checkout__edit-guests-icon" /> Edit Guests
          </div>
        )}
        {showEditAddons && (
          <div
            className="checkout__edit-addons"
            onClick={() => handleStepClick("checkout")}
          >
            <EditAddonIcon className="checkout__edit-addons-icon" /> Edit
            Add-ons
          </div>
        )}
        <div className="checkout__cancel-order" onClick={() => cancelOrder()}>
          <CrossIcon className="checkout__cancel-order-icon" />
          Cancel Order
        </div>
        {/* <div className="checkout__seat-details" style={{ marginLeft: "2rem" }}>
          <TicketsDetails tickets={tickets} count={count} clickable={event?.isSeated}/>
        </div> */}
      </div>
      <CheckoutReceipt
        tickets={Object.keys(tickets).map((key) => {
          return { ...tickets[key] };
        })}
        subtotal={subtotal}
        addons={Object.entries(selectedAddons).map((addon) => addon[1])}
        setGrandTotal={setGrandTotal}
        grandTotal={grandTotal}
        showReceipt={showReceipt}
        isFoc={isFoc}
        currency={currency}
        commission={commission}
      />
    </div>
  );
};

export default CheckoutDetails;
